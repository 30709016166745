// usage:
// {{ $lang('header.please_login', $route) }}
// langList = ["zh-cn", "en-us"]
export const lang = {
	common: {
		name: 'English'
	},
	// {{ $lang('components.currency_symbol_left', $route) }}
	// {{ $lang('components.currency_symbol_right', $route) }}
	components: {
		'index': 'Index',
		'goods_recommend': 'Goods Recommend',
		'currency_symbol': '$',
		'currency_symbol_left': '$',
		'currency_symbol_right': '',
		'discount_left' : '',
		'discount_right' : '0% off',
	},
	http: {
		'login_error': 'Login Error',
		'params_must_array': 'The parameter must be an array',
	},
	cargo: {
		'promotions_name': 'Promotion Name',
		'sale_num': 'sale out',
		'stock_num': 'in stock',
		'self_operated': 'Self Operated',
		'free_shipping': 'Free Shipping',
		'virtual_product': 'Virtual Product',
	},
	index: {
		'coupon_center': 'Coupon Center',
		'more': 'More',
	},
	header: {
		'please_login': 'Please login',
		'please_register': 'Please register',
		'login_with': 'Login with',
		'forgot_password': 'Forgot password',
		'forgot_password_email': 'Forgot password email',
		'reset_password': 'Reset password',
		'logout': 'Log out',
		'login': 'Login',
		'register': 'Register',
		'my_order': 'My Order',
		'my_visit': 'My Visit',
		'my_collection': 'My Collection',
		'help_center': 'Help Center',
		'notice': 'Notice',
		'about': 'About',
		'shop': 'Shop'
	},
	orderMethod: {
		'payment_adjusted': 'The merchant has adjusted the amount to $',
		'continue_pay': ', continue to pay?',
		'prompt': 'Prompt',
		'confirm': 'Confirm',
		'cancel': 'Cancel',
		'close_order_confirm': 'Are you sure to close the order?',
		'close_order_success': 'Order closed successfully',
		'order_delivery_ok': 'Goods have been received',
		'order_delivery_done': 'Goods received',
	},
	util: {
		'sun': 'Sun',
		'mon': 'Mon',
		'tue': 'Tue',
		'wed': 'Wed',
		'thu': 'Thu',
		'fri': 'Fri',
		'sat': 'Sat',
		'copy_success': 'Copy successfully',
		'obj_is_not_an_object': 'obj is not an object',
	},
	goodsItem: {
		'stock_num': 'In Stock: ',
		'sale_num': 'Sales: ',
		'jump_good': 'View Goods',
		'goods_sku': 'Goods ID',
	},
	orderItem: {
		'order_status': 'Order Status: ',
		'order_num': 'Order Number: 12345678',
		'jump_order': 'Check Order',
		'order_detail': 'Order Details',
	},
	serviceMessage: {
		'onsultation': 'Customer service consultation',
		'no_history': 'No chat history',
		'click_to_load': 'Click to load',
		'service_online': 'Customer service is online',
		'service_not_online': 'Customer service is not online',
		'send': 'Send',
		'today': 'Today ',
		'tomorrow': 'Tomorrow ',
		'yesterday': 'Yesterday ',
		'new_version': 'New Version',
		'switch_contacts': 'switch contacts',
		'max_words': 'Max 150 words can be sent at a time',
		'no_blank': 'Cannot send blank',
		'socket_open': 'open socket',
		'reset_hb': 'reset HB',
		'start_hb': 'start HB',
		'pinging': 'Ping...',
		'close_socket': 'close socket',
		'close_dialog': 'close dialog',
	},
	CopyRight: {
		'icp_num': 'ICP Number: ',
		'ga_num': 'GA Number: ',
	},
	NsAside: {
		'my_cart': 'My Cart',
		'my_orders': 'My Orders',
		'my_footprint': 'My Footprint',
		'my_collection': 'My Collection',
		'my_coupon': 'My Coupon',
		'service': 'Customer Service',
		'go_top': 'Back to Top',
		'site_service': 'Site Service',
		'not_login': 'Login required',
	},
	NsFooter: {
		'links': 'Links',
		'service_phone': 'Service Hotline',
		'wx_microprogram': 'WeChat MicroProgram',
	},
	NsHeader: {
		'all_category': 'All Categories',
	},
	NsHeaderMid: {
		'goods': 'Goods',
		'search': 'Search',
		'hot_search': 'Hot Searches: ',
		'my_cart': 'My Cart',
		'newly_added': 'Newly added product',
		'delete': 'Delete',
		'total': 'A total of ',
		'products': ' products',
		'total_amount': 'total amount is ',
		'goto_cart': 'Goto cart',
		'empty_cart': 'Cart is empty, go shopping and pick up items!',
		'good': 'Good',
		'shop': 'Shop',
		'unit_price': 'Unit Price',
	},
	ShopHeader: {
		'welcome1': 'Welcome to ',
		'welcome2': '欢迎您',
		'all_category': 'All Categories',
		'shop_home': 'Shop Home',
		'all_goods': 'All Goods',
		'category_menu': 'Category Menu',
	},
	pay_index: {
		'pay_success': 'Order submit successful and is processing!',
		'amounts_topay': 'Amounts to pay: ',
		'order_info': 'Order info: ',
		'trade_num': 'Order number: ',
		'pay_detail': 'Order detail: ',
		'pay_amount': 'Order amount: ',
		'create_time': 'Create time: ',
		'pay_method': 'Payment method',
		'no_pay_method': 'No payment method available',
		'pay_now': 'Pay now',
		'pay_confirm': 'Please confirm the payment is complete',
		'pay_nextstep_info': 'Please click the corresponding button below before payment',
		'back_home': 'Back Home',
		'pay_done': 'Payment complete',
		'wxpay_qrcode': 'WeChat scan code payment',
		'pay_by_alipay': 'Alipay',
		'pay_by_wechat': 'WeChat Pay',
	},
	pay_result: {
		'payment_successful': 'Payment Successful',
		'payment_failed': 'Payment Failed',
		'payment_amount': 'Amount: ',
		'member_centre': 'Member Centre',
		'back_home': 'Back to Home',
	},
	
	tmdDOTco: {
		'00200': 'No payment information',
		'00201': 'Home',
		'00202': 'Help list',
		'00203': 'Help details',
		'00204': 'Home',
		'00205': 'Help',
		'00206': 'Help list',
		'00207': 'Home',
		'00208': 'Announcement list',
		'00209': 'Announcement details',
		'00210': 'Home',
		'00211': 'Announcement',
		'00212': 'Latest Announcement',
		'00213': 'Shop Announcement',
		'00214': 'Previous',
		'00215': 'Next',
		'00216': 'Sign up',
		'00217': 'Username',
		'00218': 'Please enter your username',
		'00219': 'Password',
		'00220': 'Please enter your password',
		'00221': 'Confirm Password',
		'00222': 'Please enter the confirmation password',
		'00223': 'Verification code',
		'00224': 'Please enter the verification code',
		'00225': 'Read and agree',
		'00226': '"Service Agreement"',
		'00227': 'Already have an account, log in now',
		'00228': 'Sign up now',
		'00229': 'Mobile phone SMS code registration',
		'00230': 'Please enter your phone number',
		'00231': 'Please enter the verification code',
		'00232': 'Please enter the SMS code',
		'00233': 'Read and agree',
		'00234': '"Service Agreement"',
		'00235': 'Already have an account, log in now',
		'00236': 'Sign up now',
		'00237': 'Points reward',
		'00238': 'Points',
		'00239': 'Points can be used for order discounts or goods exchanges.',
		'00240': 'View now',
		'00241': 'Growth points',
		'00242': 'Growth points',
		'00243': 'Used to upgrade membership level',
		'00244': 'View now',
		'00245': 'Red Pocket Reward',
		'00247': 'Red Pocket cannot be withdrawn, it can be deducted from the order amount',
		'00248': 'View now',
		'00249': 'Coupon reward',
		'00250': 'Orders over ',
		'00252L': '',
		'00252R': ' off',
		'00255L': '',
		'00255R': ' off',
		'00256': 'View now',
		'00257': 'Please enter the password again',
		'00258': 'The passwords entered twice are inconsistent!',
		'00259': 'Please enter a password',
		'00260': 'The password length cannot be less than',
		'00261': 'bit',
		'00262': 'Password must contain',
		'00263': 'number',
		'00264': ', lowercase letters',
		'00265': ', capital letters',
		'00266': ', special characters',
		'00267': 'The phone number cannot be empty',
		'00268': 'Please enter the correct phone number',
		'00269': 'Please enter a username',
		'00270': 'Please enter the verification code',
		'00271': 'Please enter the verification code',
		'00272': 'Please enter the SMS code',
		'00273': 'Get SMS code',
		'00274L': 'Code can be resent after ',
		'00274R': 's',
		'00275': 'Registration is disabled',
		'00276': 'Registration is not enabled',
		'00277': 'Please read the agreement and check to agree it',
		'00278': 'Username only allows letters and numbers',
		'00279': 'Please read the agreement and check to agree it',
		'00280': 'The cell number cannot be empty',
		'00281': 'Please enter your cell number',
		'00282': 'Please enter a new password',
		'00283': 'The password length cannot be less than',
		'00284': '',
		'00285': 'Password must contain',
		'00286': 'number',
		'00287': ', lowercase letters',
		'00288': ', capital letters',
		'00289': ', special characters',
		'00290': 'Please enter the confirmation password',
		'00291': 'The two passwords entered are different',
		'00292': 'Get SMS code',
		'00293': 'Please enter the verification code',
		'00294': 'Please enter the SMS code',
		'00295': 'Get SMS code',
		'00296': 'This phone number is not registered',
		'00297L': 'Code can be resent after ',
		'00297R': 's',
		'00298': 'Enter cell number',
		'00299': 'Authenticate identity',
		'00300': 'Reset password',
		'00301': 'Done',
		'00302': 'Please enter the registered cell phone number',
		'00303': 'Next',
		'00304': 'Please enter the verification code',
		'00305': 'Please enter the SMS code',
		'00306': 'Next',
		'00307': 'Please enter a new login password',
		'00308': 'Please enter the new password again',
		'00309': 'Reset password',
		'00310': 'Reset password successful',
		'00311': 'Login again',
		'00312': 'Account login',
		'00313': 'Please enter you account',
		'00314': 'Please enter your password',
		'00315': 'Please enter the verification code',
		'00316': 'Keep login in 7days',
		'00317': 'Forgot password',
		'00318': 'Login',
		'00319': 'No account yet? ',
		'00320': 'Sign up now',
		'00321': 'Scan WeChat QRcode to log in',
		'00322': 'Cell phone SMS code login',
		'00323': 'Please enter your cell phone number',
		'00324': 'Please enter the verification code',
		'00325': 'Please enter the SMS code',
		'00326': 'Login',
		'00327': 'Sign up now',
		'00328': 'Scan WeChat QRcode to log in',
		'00329': 'Login with username and password',
		'00330': 'Scan QRcode successfully',
		'00331': 'Please enter your cell phone number',
		'00332': 'Please enter the verification code',
		'00333': 'Please enter the SMS code',
		'00334': 'OK',
		'00335': 'Login with other method',
		'00336': 'Sign up now',
		'00337': 'The cell phone number cannot be empty',
		'00338': 'Please enter the correct cell phone number',
		'00339': 'Get SMS code',
		'00340': 'Please enter your username',
		'00341': 'Please enter your password',
		'00342': 'Please enter the verification code',
		'00343': 'Please enter the verification code',
		'00344': 'Please enter the SMS code',
		'00345': 'Please enter the verification code',
		'00346': 'Please enter the SMS code',
		'00347': 'Please enter the verification code',
		'00348': 'Get SMS code',
		'00349': 'Successful login! ',
		'00350': 'Successful login! ',
		'00351': 'Successful login! ',
		'00352': 'Successful login! ',
		'00353L': 'Code can be resent after ',
		'00353R': 's',
		'00354L': 'Code can be resent after ',
		'00354R': 's',
		'00355': 'The site is temporarily closed',
		'00356': 'Back to Previous',
		'00357': 'Back to Home',
		'00358': '0% off',
		'00359L': 'For orders over ',
		'00359R': '',
		'00360L': 'For orders over ',
		'00360R': '',
		'00361': 'Get it now',
		'00362': 'Go to use',
		'00363': 'Limited time offer',
		'00364': 'More',
		'00365': 'Product',
		'00366': 'Shop',
		'00367': 'Search',
		'00368': 'Shopping cart',
		'00369': 'Time to end',
		'00370': 'Goods',
		'00371': 'Goods',
		'00372': 'Shop',
		'00373': 'Event has ended',
		'00374': 'Get coupon list',
		'00375': 'Get a coupon',
		'00376': 'Received successfully',
		'00377': 'Receive coupons',
		'00378': 'You have not logged in, please log in first',
		'00379': 'Product Image',
		'00382': 'Product Image',
		'00385': 'Product Image',
		'00386': 'Product Image',
		'00387': 'No category yet',
		'00388': 'Coupon Center',
		'00389': 'Save money, get coupons',
		'00390': 'Coupons for a limited time',
		'00391': 'Coupons can be stacked',
		'00392': 'variety',
		'00393': 'My Coupon',
		'00394': '0% off',
		'00395': '\'This coupon is valid for all products\'',
		'00396': '\'This coupon is valid for specific products\'',
		'00397': 'No threshold coupon',
		'00398L': 'For orders over',
		'00398R': '',
		'00399L': 'Coupons are valid within',
		'00399R': 'days after collection',
		'00400': 'Valid until',
		'00401': 'Sold out',
		'00402': 'Receive now',
		'00403': 'Go to use',
		'00404': 'No coupons yet',
		'00405': 'Previou',
		'00406': 'Next',
		'00407': 'Coupon plugin not installed',
		'00408': 'Coupon plugin not installed',
		'00409': 'Received successfully',
		'00410': 'You have not logged in, please log in first',
		'00411': 'Are you sure you want to delete this product? ',
		'00412': 'Prompt message',
		'00413': 'OK',
		'00414': 'Cancel',
		'00415': 'Deleted successfully',
		'00416': 'Please select the item to delete',
		'00417': 'Are you sure you want to delete the selected product? ',
		'00418': 'Prompt message',
		'00419': 'OK',
		'00420': 'Cancel',
		'00421': 'Deleted successfully',
		'00422': 'Are you sure you want to clear these items? ',
		'00423': 'Prompt message',
		'00424': 'OK',
		'00425': 'Cancel',
		'00426': 'Deleted successfully',
		'00427': 'Product details',
		'00428': 'Price',
		'00429': 'Quantity',
		'00430': 'Subtotal',
		'00431': 'Operation',
		'00432': 'Delete',
		'00433': 'Invalid goods',
		'00434': 'Pieces',
		'00435': 'Invalid',
		'00436': 'Select all',
		'00437': 'Delete',
		'00438': 'Clear invalid goods',
		'00439': 'Selected product',
		'00440': 'Pieces',
		'00441': 'Total (excluding shipping fee):',
		'00442': 'Settlement',
		'00443': 'Settlement',
		'00444': 'Your shopping cart is empty, go and pick goods! ',
		'00445': 'Time to the event end ',
		'00446': 'This video is temporarily unavailable, please try again later',
		'00447': 'You are not logged in',
		'00448': 'All information',
		'00449': 'Pieces',
		'00450': 'Manila',
		'00451': 'Manila',
		'00452': 'Manila',
		'00453': 'Manila',
		'00454': 'Tondo',
		'00455': 'Tondo',
		'00456': 'You have not logged in, please log in first',
		'00457': 'You have not logged in, please log in first',
		'00459': 'Pieces',
		'00460': 'For orders over ',
		'00461': ' off ',
		'00463': '; For orders over ',
		'00464': ' off ',
		'00466': 'You will get ',
		'00467': ' points',
		'00468': 'You will get ',
		'00469': ' pieces ',
		'00470': '% off Coupons',
		'00471': '; You will get ',
		'00472': ' pieces ',
		'00473': '% off Coupons',
		'00474': 'You will get ',
		'00475': ' pieces ',
		'00476': ' Coupon',
		'00477': '; You will get ',
		'00478': ' pieces ',
		'00479': ' Coupon',
		'00480': 'For orders over ',
		'00481': 'For orders over ',
		'00482': 'For orders over ',
		'00483': ' free shipping',
		'00484': '; For orders over ',
		'00485': ' free shipping',
		'00486': 'Received successfully',
		'00487': 'Item is sold out',
		'00488': 'The purchase quantity cannot be 0',
		'00489': 'Add to cart successfully',
		'00490': 'You have not logged in, please log in first',
		'00491': 'Item is sold out',
		'00492': 'The purchase quantity cannot be 0',
		'00493': 'Purchase limit ',
		'00494': ' pieces, now purchased',
		'00495': ' pieces, you can purchase no more than ',
		'00496': ' pieces',
		'00497': 'Event has ended',
		'00498': 'Video',
		'00499': 'Image',
		'00500': 'Favourite products',
		'00501': 'Contact customer service',
		'00502': 'Contact customer service',
		'00503': 'Limited time discount',
		'00504': '\'days\'',
		'00505': '\'hour\'',
		'00506': '\'minutes\'',
		'00507': '\'seconds\'',
		'00508': 'Sale price',
		'00509': 'Original price',
		'00510': 'Member price',
		'00511': 'Market price',
		'00512': 'Total reviews',
		'00513': 'Total sales',
		'00514': 'Coupons',
		'00517': 'Discount coupons',
		'00518': 'Get it free coupons',
		'00519': 'Shipping fee',
		'00520': 'Free shipping',
		'00521': 'No free shipping',
		'00522': 'Ship to ',
		'00523': 'Please select a shipping address ',
		'00524': 'Please select a state ',
		'00525': 'Please select a city ',
		'00526': 'Please select a district ',
		'00527': 'Service ',
		'00528': ' by ',
		'00529': ' shipping and providing after-sales service',
		'00530': 'Quantity',
		'00531': 'Inventory',
		'00532': 'Insufficient stock',
		'00533': 'The maximum purchase limit has been reached',
		'00534': 'Buy Now',
		'00535': 'Add to cart',
		'00536': 'This product is no longer available',
		'00537': 'QR code',
		'00538': 'This product is no longer available',
		'00539': 'QR code',
		'00540': 'Product service',
		'00541': 'Package price',
		'00542': 'Buy Now',
		'00543': 'Product Details',
		'00544': 'Product attributes',
		'00545': '\'Product reviews\'',
		'00546': '\'Product review\'',
		'00547': 'All comments',
		'00548': 'Positive comments',
		'00549': 'Neutral comments',
		'00550': 'Negative comments',
		'00551': 'Response from the shop:',
		'00552': 'additional comment',
		'00553': 'Response from the shop:',
		'00554': 'Previous ',
		'00555': 'Next ',
		'00556': 'There are no comment for this product yet',
		'00557': 'Home',
		'00558': 'Home',
		'00559': 'Category:',
		'00560': 'All',
		'00561': 'Multiple selection',
		'00562': 'General',
		'00563': 'Sales',
		'00564': 'Price',
		'00565': 'Free shipping',
		'00566': 'Lowest price',
		'00567': 'Maximum price',
		'00568': 'OK',
		'00569': ' people have paid',
		'00570': ' Free shipping',
		'00571': 'Limited time discount',
		'00572': 'Previous ',
		'00573': 'Next ',
		'00574': 'No iteam was found, try again ',
		'00575': 'Product category search',
		'00576': 'Add to cart successfully',
		'00577': 'Added to favorite',
		'00578': 'Cancel favorite successfully',
		'00579': 'My balance ',
		'00580': 'Withdraw',
		'00581': 'Recharge',
		'00582': 'Withdrawable balance:',
		'00583': 'Non-withdrawable balance:',
		'00584': 'Source',
		'00585': 'Amount',
		'00586': 'Detailed description',
		'00587': 'time',
		'00588': 'Previous',
		'00589': 'Next',
		'00590': 'Edit account',
		'00591': 'Name',
		'00592': 'Please enter your real name',
		'00593': 'Cell phone',
		'00594': 'Please enter your phone number',
		'00595': 'Account Type',
		'00596': 'Please select an account type',
		'00597': 'Bank name',
		'00598': 'Please enter the bank name',
		'00599': 'Withdraw account',
		'00600': 'Please enter the withdrawal account number',
		'00601': 'Save',
		'00602': 'The phone number cannot be empty',
		'00603': 'Please enter the correct cell number',
		'00604': 'Please enter your real name',
		'00605': 'Please select an account type',
		'00606': 'Please enter the bank name',
		'00607': 'Please enter the withdrawal account number',
		'00608': 'Account list',
		'00609': '+ Add new account',
		'00610': 'Default',
		'00611': 'Cell phone number:',
		'00612': 'Withdrawal account:',
		'00613': 'Account type:',
		'00614': 'Bank Name:',
		'00615': 'Set as default',
		'00616': 'Edit',
		'00617': 'Delete',
		'00618': 'Previous',
		'00619': 'Next',
		'00620': 'Bank',
		'00621': 'Alipay',
		'00622': 'WeChat',
		'00623': 'The default account set successfully',
		'00624': 'Are you sure you want to delete this account?',
		'00625': 'Prompt',
		'00626': 'OK',
		'00627': 'Cancel',
		'00628': 'Edit shipping address',
		'00629': 'Name',
		'00630': 'Consignee Name',
		'00631': 'Cell phone',
		'00632': 'Consignee\'s phone number',
		'00633': 'Phone',
		'00634': 'Consignee\'s telephone number (optional)',
		'00635': 'Address',
		'00636': 'Please select a state',
		'00637': 'Please select a city',
		'00638': 'Please select a district',
		'00639': 'Detailed address',
		'00640': 'Locate to residential areas, streets, and buildings',
		'00641': 'Is it the default',
		'00642': 'Yes',
		'00643': 'No',
		'00644': 'Save',
		'00645': 'The phone number cannot be empty',
		'00646': 'Please enter the correct phone number',
		'00647': 'Please select a district',
		'00648': 'Please select a city',
		'00649': 'Please select a state',
		'00650': 'Please enter the consignee\'s name',
		'00651': 'Please enter the detailed address',
		'00652': 'Account Balance',
		'00653': 'Withdrawal',
		'00654': 'Withdraw to:',
		'00655': 'WeChat withdrawal is not supported, please choose Alipay',
		'00656': 'Withdrawal account:',
		'00657': 'Please select a withdrawal account',
		'00658': 'Withdrawal amount:',
		'00659': 'The balance that can be withdrawn is',
		'00660': 'Withdraw all',
		'00661': 'The minimum withdrawal amount is ',
		'00662': 'The handling fee is ',
		'00663': 'Withdraw',
		'00664': 'Please add the withdrawal method first',
		'00665': 'Please enter the withdrawal amount',
		'00666': 'The amount exceeds the amount that can be withdrawn',
		'00667': 'The amount is less than the minimum withdrawal amount',
		'00668': 'The withdrawal application is successful! ',
		'00669': 'Goods',
		'00670': 'Previous',
		'00671': 'Next',
		'00672': 'You haven\'t follow this good',
		'00673': 'Guess you like',
		'00674': 'Product Image',
		'00677': 'Unfollowed successfully',
		'00678': 'My Coupon',
		'00679': 'Not used',
		'00680': 'Used',
		'00681': 'Expired',
		'00683L': 'For orders over ',
		'00683R': ' can use coupon ',
		'00684': 'No threshold coupon',
		'00685L': 'Valid within',
		'00685R': 'days from the date of receive',
		'00686': 'Valid until',
		'00687': 'Previous',
		'00688': 'Next',
		'00689': 'You have no coupon yet',
		'00690': 'Coupon plugin not installed',
		'00691': 'Coupon plugin not installed',
		'00692': 'You have no coupon yet',
		'00693': 'You have not used any coupon yet',
		'00694': 'You have no expired coupons',
		'00695': 'My footprint',
		'00696': 'Temporarily no footprint',
		'00697': 'Member Center',
		'00698': 'Welcome page',
		'00699': 'Personal Information',
		'00700': 'Account Security',
		'00701': 'Shipping address',
		'00702': 'My favorite',
		'00703': 'My footprints',
		'00704': 'Order Center',
		'00705': 'My order',
		'00706': 'Refund/After-sale',
		'00707': 'Verification desk',
		'00708': 'Verification record',
		'00709': 'Account Center',
		'00710': 'Account Balance',
		'00711': 'Withdrawal record',
		'00712': 'My Coupon',
		'00713': 'My points',
		'00714': 'Account list',
		'00715': 'Shipping address',
		'00716': '+ Add shipping address',
		'00717': 'Default',
		'00718': 'Set as default',
		'00719': 'Edit',
		'00720': 'Delete',
		'00721': 'Previous',
		'00722': 'Next',
		'00723': 'The default address was modified successfully',
		'00724': 'Are you sure you want to delete this address?',
		'00725': 'Prompt',
		'00726': 'OK',
		'00727': 'Cancel',
		'00728': 'Default address, cannot be deleted!',
		'00729': 'Growth point:',
		'00730': 'Not logged in',
		'00731': 'Coupons',
		'00732': 'Points',
		'00733': 'Balance',
		'00734': 'My Favorite',
		'00735': 'Favorite goods',
		'00736': 'My order',
		'00737': 'Pending payment',
		'00738': 'Pending Shipment',
		'00739': 'Pending receipt',
		'00740': 'Pending comment',
		'00741': 'Refund/After-sale',
		'00742': 'View details',
		'00743': 'Empty here, go and pick goods! ',
		'00744': 'My footprints',
		'00745': 'Get order quantity',
		'00746': 'Basic information',
		'00747': 'Account number',
		'00748': 'Cell phone number',
		'00749': 'Unbind',
		'00750': 'nickname',
		'00751': 'Save',
		'00752': 'Avatar photo',
		'00753': 'Avatar preview',
		'00754': 'Fullfill personal information and upload avatar pictures to help you get more friends. ',
		'00755': 'The default size for the avatar is 120x120 pixels. ',
		'00756': 'Save',
		'00757': 'Please enter a nickname',
		'00758': 'The maximum length is 30 characters',
		'00759': 'The nickname has been modified successfully',
		'00760': 'Avatar modified successfully',
		'00761': 'Account Balance',
		'00762': 'List of recharge packages',
		'00763': 'Details of recharge package',
		'00764L': 'Extra gift: ',
		'00764R': ' points',
		'00765L': 'Extra gift: ',
		'00765R': ' growth points',
		'00766': 'Recharge now',
		'00767': 'My points',
		'00768': 'From',
		'00769': 'Points',
		'00770': 'Description',
		'00771': 'Time',
		'00772': 'Previous',
		'00773': 'Next',
		'00774': 'Account Balance',
		'00775': 'List of recharge packages',
		'00776': 'My available balance',
		'00777': 'Recharge record',
		'00778': 'Withdrawable balance:',
		'00779': 'Non-withdrawable balance:',
		'00780': 'Package Name',
		'00781': 'Face value',
		'00782': 'Gift Points',
		'00783': 'Give growth value',
		'00784': 'Operation',
		'00785': 'Recharge',
		'00786': 'Previous',
		'00787': 'Next',
		'00788': 'Account Balance',
		'00789': 'List of recharge packages',
		'00790': 'Recharge record',
		'00791': 'Package Name',
		'00792': 'Face value',
		'00793': 'Purchase price',
		'00794': 'Gift Points',
		'00795': 'Give growth value',
		'00796': 'Order number:',
		'00797': 'There are no orders',
		'00798': 'Previous',
		'00799': 'Next',
		'00800': 'Withdrawal record',
		'00801': 'Account Type',
		'00802': 'Withdrawal amount',
		'00803': 'Withdrawal time',
		'00804': 'Withdrawal Status',
		'00805': 'Operation',
		'00806': 'Details',
		'00807': 'No withdrawal record yet',
		'00808': 'Previous',
		'00809': 'Next',
		'00810': 'Withdrawal record',
		'00811': 'Withdrawal details',
		'00812': 'Current Status',
		'00813': 'Transaction number',
		'00814': 'Handling fee',
		'00815': 'Application time',
		'00816': 'Approval time',
		'00817': 'Bank Name',
		'00818': 'Account number',
		'00819': 'Reason for rejection',
		'00820': 'Transfer method',
		'00821': 'Transfer time',
		'00822': 'Login password',
		'00823': 'It is recommended that you change your password regularly to protect account security',
		'00824': 'Modify',
		'00825': 'SMS verification',
		'00826': 'After verification, it can be used to quickly retrieve the login password and payment password, and receive account balance change reminders',
		'00827': 'Modify',
		'00828': 'Change login password',
		'00829': 'Original password',
		'00830': 'Current password',
		'00831': 'New password',
		'00832': 'New password',
		'00833': 'Confirm Password',
		'00834': 'Please confirm the new password',
		'00835': 'Save',
		'00836': 'Cancel',
		'00837': 'Verification code',
		'00838': 'Please enter the verification code',
		'00839': 'Get dynamic code',
		'00840': 'Please enter the dynamic code',
		'00841': 'Click "Get dynamic Code", and a verification code will be sent to your bound mobile number ',
		'00842': 'New Password',
		'00843': 'New Password',
		'00844': 'Confirm Password',
		'00845': 'Please confirm the new password',
		'00846': 'Save',
		'00847': 'Cancel',
		'00848': 'Bind mailbox',
		'00849': 'Current mailbox',
		'00850': 'Mailbox',
		'00851': 'Please enter your email address',
		'00852': 'Verification code',
		'00853': 'Please enter the verification code',
		'00854': 'Dynamic code',
		'00855': 'Please enter the dynamic code',
		'00856': 'Save',
		'00857': 'Cancel',
		'00858': 'Bind phone number',
		'00859': 'Current phone number',
		'00860': 'Cell phone number',
		'00861': 'Please enter your Cell phone number',
		'00862': 'Verification code',
		'00863': 'Please enter the verification code',
		'00864': 'Dynamic code',
		'00865': 'Please enter the dynamic code',
		'00866': 'Save',
		'00867': 'Cancel',
		'00868': 'Bind payment password',
		'00869': 'Please enter a 6-digit payment password, it is recommended not to use repeated or consecutive numbers',
		'00870': 'Verification code has been sent to',
		'00871': 'Save',
		'00872': 'Cancel',
		'00873': 'Please enter a new password',
		'00874': 'The new password cannot be the same as the original password! ',
		'00875': 'Please enter the password again',
		'00876': 'The passwords entered twice are inconsistent!',
		'00877': 'Please enter a new password',
		'00878': 'The new password cannot be the same as the original password! ',
		'00879': 'Please enter the password again',
		'00880': 'The passwords entered twice are inconsistent!',
		'00881': 'Please enter a correct email address',
		'00882': 'Please enter the correct phone number',
		'00883': 'Please enter the original password',
		'00884': 'Please enter the correct email address',
		'00885': 'Please enter the verification code',
		'00886': 'Please enter a dynamic verification code',
		'00887': 'Please enter the correct phone number',
		'00888': 'Please enter the verification code',
		'00889': 'Please enter a dynamic verification code',
		'00890': 'Get verification code',
		'00891': 'Enter SMS verification code',
		'00892': 'Please enter the verification code',
		'00893': 'Please enter a dynamic verification code',
		'00894': 'Get dynamic code',
		'00895': 'Get dynamic code',
		'00896': 'Get dynamic code',
		'00897': 'You haven\'t bound your phone number yet, please bind your phone number first ',
		'00898': 'Prompt message',
		'00899': 'OK',
		'00900': 'Cancel',
		'00901': 'Password changed successfully',
		'00902': 'Mailbox bound successfully',
		'00903L': 'Sent(',
		'00903R': 's)',
		'00904': 'The current phone number already exists',
		'00905': 'Do not click repeatedly',
		'00906': 'Mobile phone number bound successfully',
		'00907L': 'Sent(',
		'00907R': 's)',
		'00908': 'Please set payment password',
		'00909': 'Please enter again',
		'00910': 'Please enter a number',
		'00911': 'Payment password changed successfully',
		'00912': 'The two passwords are not the same',
		'00913': 'Please set payment password',
		'00914L': 'Sent(',
		'00914R': 's)',
		'00915': 'Do not click repeatedly',
		'00916': 'Password changed successfully',
		'00917': 'Refund/After-sale',
		'00918': 'Product information',
		'00919': 'Refund Amount',
		'00920': 'Refund Type',
		'00921': 'Refund Status',
		'00922': 'Operation',
		'00923': 'Refund number:',
		'00924': '\'Refund successful\'',
		'00925': '\'Refunding\'',
		'00926': '\'Refund\'',
		'00927': '\'Return\'',
		'00928': 'Refund details',
		'00929': 'There is no related order',
		'00930': 'Previous',
		'00931': 'Next',
		'00932': 'After the cancellation, this application will be closed, if there are still problems in the future, you can initiate the application again',
		'00933': 'Prompt',
		'00934': 'Cancel confirm',
		'00935': 'Do not cancel now',
		'00936': 'Canceled sccessful',
		'00937': 'Refund/After-sale',
		'00938': 'Refund details',
		'00939': 'Protect my rights',
		'00940': 'Product',
		'00941': 'Quantity',
		'00942': 'Amount',
		'00943': 'Refund Amount',
		'00944': 'Reason for refund',
		'00945': 'Please select',
		'00946': 'Refund Instructions',
		'00947': 'Please enter refund description (optional)',
		'00948': 'Submit',
		'00949': 'Negotiation record',
		'00950': 'Negotiation record',
		'00951': 'Buyer',
		'00952': 'Seller',
		'00953': 'Platform',
		'00954': 'Goods',
		'00955': 'Quantity',
		'00956': 'Refund Amount',
		'00957': 'Reason for refund:',
		'00958': 'Refund amount:',
		'00959': 'Apply number:',
		'00960': 'Application time:',
		'00961': 'Refund time:',
		'00962': 'The refund information for this item has not been obtained',
		'00963': 'Please select a refund reason',
		'00964': 'After the cancellation, this application will be closed. If there are still problems in the future, you can apply again',
		'00965': 'Prompt',
		'00966': 'Cancel confirm',
		'00967': 'Do not cancel now',
		'00968': 'Canceled sccessful',
		'00969': 'Order list',
		'00970': 'Order Details',
		'00971': 'Order Status:',
		'00972': 'To pay:',
		'00973': 'Comment',
		'00974': 'Additional comments',
		'00975': 'Comment',
		'00976': 'Additional comments',
		'00977': 'Order information',
		'00978': 'Order type:',
		'00979': 'Order number:',
		'00980': 'Order transaction number:',
		'00981': 'Shipping method:',
		'00982': 'Refund method:',
		'00983': '\'Original refund\'',
		'00984': '\'Offline refund\'',
		'00985': '\'Refund to balance\'',
		'00986': 'Created:',
		'00987': 'Close time:',
		'00988': 'Payment method:',
		'00989': 'Payment time:',
		'00990': 'Shop promotions:',
		'00991': 'Buyer message:',
		'00992': 'Receipt information',
		'00993': 'Consignee:',
		'00994': 'Cell phone number:',
		'00995': 'Shipping address:',
		'00996': 'Invoice information',
		'00997': 'Invoice type:',
		'00998': '\'Paper Invoice\'',
		'00999': '\'Electronic Invoice\'',
		'01000': 'Invoice header type:',
		'01001': '\'Personal\'',
		'01002': '\'Enterprise\'',
		'01003': 'Invoice title:',
		'01004': 'Invoice content:',
		'01005': 'Invoice mailing address:',
		'01006': 'Invoice receiving email:',
		'01007': 'Goods information',
		'01008': 'Unit price',
		'01009': 'Quantity',
		'01010': 'Subtotal',
		'01011': 'Operation',
		'01012': 'Refund',
		'01013': 'Check refund',
		'01014': 'Product amount:',
		'01015': 'Shipping:',
		'01016': 'Membership card:',
		'01017': 'Tax:',
		'01018': 'Invoice mailing fee:',
		'01019': 'Order adjustment:',
		'01020': 'Offer:',
		'01021': 'Coupon:',
		'01022': 'Point deduction:',
		'01023': 'Balance used:',
		'01024': 'Actual payment:',
		'01025': 'Can not get order information',
		'01026': 'Order list',
		'01027': 'Order Details',
		'01028': 'Order Status:',
		'01029': 'To pay:',
		'01030': 'Comment',
		'01031': 'Addtional comments',
		'01032': 'Comment',
		'01033': 'Addtional comments',
		'01034': 'Order information',
		'01035': 'Order type:',
		'01036': 'Order number:',
		'01037': 'Order transaction number:',
		'01038': 'Shipping Method:',
		'01039': 'Created:',
		'01040': 'Close time:',
		'01041': 'Payment method:',
		'01042': 'Payment time:',
		'01043': 'Shop promotions:',
		'01044': 'Buyer message:',
		'01045': 'Receipt Information',
		'01046': 'Consignee:',
		'01047': 'Cell phone number:',
		'01048': 'Shipping address:',
		'01049': 'Product information',
		'01050': 'Unit price',
		'01051': 'Quantity',
		'01052': 'Subtotal',
		'01053': 'Operation',
		'01054': 'Refund',
		'01055': 'Check refund',
		'01056': 'Product amount:',
		'01057': 'Shipping fee:',
		'01058': 'Membership card:',
		'01059': 'Tax:',
		'01060': 'Invoice mailing fee:',
		'01061': 'Order adjustment:',
		'01062': 'Offer:',
		'01063': 'Coupon:',
		'01064': 'Point deduction:',
		'01065': 'Balance used:',
		'01066': 'Actual payment:',
		'01067': 'Can not get order information',
		'01068': 'Order list',
		'01069': 'Order Details',
		'01070': 'Order Status:',
		'01071': 'To pay:',
		'01072': 'Comment',
		'01073': 'Addtional comments',
		'01074': 'Comment',
		'01075': 'Addtional comments',
		'01076': 'Self-pickup point:',
		'01077': 'Pickup code:',
		'01078': 'Business hours:',
		'01079': 'Contact:',
		'01080': 'Detailed address:',
		'01081': 'Product Information',
		'01082': 'Unit price',
		'01083': 'Quantity',
		'01084': 'Subtotal',
		'01085': 'Operation',
		'01086': 'Order information',
		'01087': 'Order type:',
		'01088': 'Order number:',
		'01089': 'Order transaction number:',
		'01090': 'Shipping method:',
		'01091': 'Creation time:',
		'01092': 'Close time:',
		'01093': 'Payment method:',
		'01094': 'Payment time:',
		'01095': 'Shop promotions:',
		'01096': 'Buyer message:',
		'01097': 'Receipt information',
		'01098': 'Consignee:',
		'01099': 'Mobile phone number:',
		'01100': 'Shipping address:',
		'01101': 'Refund',
		'01102': 'View refund',
		'01103': 'Product amount:',
		'01104': 'Shipping fee:',
		'01105': 'Membership card:',
		'01106': 'Tax:',
		'01107': 'Invoice mailing fee:',
		'01108': 'Order adjustment:',
		'01109': 'Offer:',
		'01110': 'Coupon:',
		'01111': 'Balance used:',
		'01112': 'Point deduction:',
		'01113': 'Actual payment:',
		'01114': 'Can not get order information',
		'01115': 'Order list',
		'01116': 'Order Details',
		'01117': 'Order Status:',
		'01118L': '',
		'01118R': ' before the order closed automatically',
		'01119': 'Payment required:',
		'01120': 'Comment',
		'01121': 'Addtional comments',
		'01122': 'Comment',
		'01123': 'Addtional comments',
		'01124': 'Verification code',
		'01125': 'Please show the QR code to the verification staff',
		'01126': 'Write-off code:',
		'01127': 'Write-off information',
		'01128': 'Number of write-offs: ',
		'01129L': ' times remain/Total ',
		'01129R': ' times',
		'01130': 'Validity to:',
		'01131': 'permanently valid',
		'01132': 'Write-off record',
		'01133': 'Approved by:',
		'01134': 'Write-off time:',
		'01135': 'Card and password info',
		'01136': 'Card number:',
		'01137': 'Password:',
		'01138': 'Order information',
		'01139': 'Order type:',
		'01140': 'Order number:',
		'01141': 'Order transaction number:',
		'01142': 'Created time:',
		'01143': 'Close time:',
		'01144': 'Payment method:',
		'01145': 'Payment time:',
		'01146': 'Shop promotions:',
		'01147': 'Buyer message:',
		'01148': 'Invoice information',
		'01149': 'Invoice type:',
		'01150': '\'Paper Invoice\'',
		'01151': '\'Electronic Invoice\'',
		'01152': 'Invoice type:',
		'01153': '\'Personal\'',
		'01154': '\'Enterprise\'',
		'01155': 'Invoice title:',
		'01156': 'Invoice content:',
		'01157': 'Invoice mailing address:',
		'01158': 'Invoice receiving email:',
		'01159': 'Product information',
		'01160': 'Unit price',
		'01161': 'Quantity',
		'01162': 'Subtotal',
		'01163': 'Operation',
		'01164': 'Refund',
		'01165': 'View refund',
		'01166': 'Product amount:',
		'01167': 'Membership card:',
		'01168': 'Tax:',
		'01169': 'Invoice mailing fee:',
		'01170': 'Adjustment:',
		'01171': 'Offer:',
		'01172': 'Coupon:',
		'01173': 'Point deduction:',
		'01174': 'Balance used:',
		'01175': 'Actual payment:',
		'01176': 'Can not get order information',
		'01177': 'Description matches:',
		'01178': 'Positive comments',
		'01179': 'Neutral comments',
		'01180': 'Negative comments',
		'01181': 'Please enter your review here',
		'01182': 'Please enter your review here',
		'01183L': 'A total of 6 photos can be uploaded, ',
		'01183R': ' remaining',
		'01184': 'Submit',
		'01185': 'A total of 6 photos can be uploaded',
		'01186': 'Comment cannot be empty',
		'01187': 'Comment cannot be empty',
		'01188': 'Review done',
		'01189': 'My order',
		'01190': 'All orders',
		'01191': 'Pending payment',
		'01192': 'Pending Shipment',
		'01193': 'Pending delivery',
		'01194': 'Waiting review',
		'01195': 'Product Information',
		'01196': 'Unit price',
		'01197': 'Quantity',
		'01198': 'actual payment',
		'01199': 'Order Status',
		'01200': 'Operation',
		'01201': 'Order number:',
		'01202': 'The distance from the order is automatically closed, remaining',
		'01202L': '',
		'01202R': ' before the order closed automatically',
		'01203': 'Order Details',
		'01204': 'Comment',
		'01205': 'Additional comments',
		'01206': 'Comment',
		'01207': 'Additional comments',
		'01208': 'No related orders yet',
		'01209': 'Previous',
		'01210': 'Next',
		'01211': 'Order list',
		'01212': 'Logistics details',
		'01213': 'Waybill number:',
		'01214': 'Logistics company:',
		'01215': 'No logistics information! ',
		'01216': 'My order',
		'01217': 'Order Details',
		'01218': 'Refund',
		'01219': 'Product',
		'01220': 'Quantity',
		'01221': 'Amount',
		'01222': 'Refund Type',
		'01223': 'Refund without return',
		'01224': 'Return and refund',
		'01225': 'Refund Amount',
		'01226': 'Reason for refund',
		'01227': 'Please select',
		'01228': 'Refund Instructions',
		'01229': 'Please enter refund description (optional)',
		'01230': 'Submit',
		'01231': 'No refund information found for this item! ',
		'01232': 'Apply successful! ',
		'01233': 'Please select a refund reason',
		'01234': 'Refund/After-sale',
		'01235': 'Refund Details',
		'01236': 'Successful rights protection',
		'01237': 'Reason for rejection:',
		'01238': 'If the merchant refuses, you can resend the application',
		'01239': 'If the merchant agrees, the application will be approved and refunded to you',
		'01240': 'If the merchant fails to process within the time limit, the platform will automatically approve the application and refund you',
		'01241': 'If the merchant confirms receipt, a refund will be given to you',
		'01242': 'If the merchant refuses to accept the goods, the refund will be closed and you can re-initiate the refund',
		'01243': 'Negotiation record',
		'01244': 'Negotiation record',
		'01245': '\'Buyer\'',
		'01246': '\'Seller\'',
		'01247': 'Consignee:',
		'01248': 'Contact number:',
		'01249': 'Return address:',
		'01250': 'Product',
		'01251': 'Quantity',
		'01252': 'Refund Amount',
		'01253': 'Refund method:',
		'01254': '\'Refund only\'',
		'01255': '\'Refund Return\'',
		'01256': 'Reason for refund:',
		'01257': 'Refund amount:',
		'01258': 'Refund number:',
		'01259': 'Application time:',
		'01260': 'Refund time:',
		'01261': 'Platform rights protection',
		'01262': 'Enter shipping logistics',
		'01263': 'Logistics company',
		'01264': 'Please enter the logistics company',
		'01265': 'Logistics number',
		'01266': 'Please enter the tracking number',
		'01267': 'Logistics description',
		'01268': 'Optional',
		'01269': 'Cancel',
		'01270': 'OK',
		'01271': 'Can not get the refund information for this line item! ',
		'01272': 'Please enter the logistics company',
		'01273': 'Please enter the tracking number',
		'01274': 'Can not get the refund information for this line item! ',
		'01275': 'After the cancellation, this application will be closed, if there are still problems in the future, you can initiate the application again',
		'01276': 'Prompt',
		'01277': 'Confirm cancel',
		'01278': 'Not yet canceled',
		'01279': 'Cancel successful! ',
		'01280': 'Write-off desk',
		'01281': 'Enter write-off code',
		'01282': 'Write-off',
		'01283': 'Please enter the write-off code',
		'01284': 'Confirm',
		'01285': 'Please enter the write-off code',
		'01286': 'Write-off record',
		'01287': 'Write-off verification',
		'01288': 'Quantity:',
		'01289': 'Write-off type:',
		'01290': 'Write-off status: written-off',
		'01291': 'Write-off operator:',
		'01292': 'Write-off time:',
		'01293': 'Confirm write-off',
		'01294': 'Write-off record',
		'01295': 'Product information',
		'01296': 'Unit price',
		'01297': 'Quantity',
		'01298': 'Operator:',
		'01299': 'No related orders yet',
		'01300': 'Previous',
		'01301': 'Next',
		'01302': 'Shipping address',
		'01303': 'Add shipping address',
		'01304': 'Edit',
		'01305': 'Are you sure you want to delete this address? ',
		'01306': 'Delete',
		'01307': 'More shipping addresses',
		'01308': 'You need to fill in your cell phone number to virtual goods',
		'01309': 'Cell phone number',
		'01310': 'Please enter your cell phone number',
		'01311': '\'Add shipping address\'',
		'01312': '\'Edit shipping address\'',
		'01313': 'Name',
		'01314': 'Consignee name',
		'01315': 'Cell phone',
		'01316': 'Consignee phone number',
		'01317': 'Phone',
		'01318': 'Consignee\'s landline (optional)',
		'01319': 'Region',
		'01320': 'Please select a state',
		'01321': 'Please select a state',
		'01322': 'Please select a city',
		'01323': 'Please select a city',
		'01324': 'Please select a district',
		'01325': 'Please select a district',
		'01326': 'Detailed address',
		'01327': 'Streets, buildings',
		'01328': 'Cancel',
		'01329': 'OK',
		'01330': 'Whether to use the balance',
		'01331': 'Do not use balance',
		'01332': 'Balance used',
		'01333': 'Whether to use points',
		'01334': 'Do not use points',
		'01335': 'Use points',
		'01336': 'Shipping method',
		'01337': 'Invoice information',
		'01338': 'No invoice required',
		'01339': 'Invoice required',
		'01340': 'Invoice type:',
		'01341': 'Paper',
		'01342': 'Electronic',
		'01343': 'Invoice header:',
		'01344': 'Personal',
		'01345': 'Enterprise',
		'01346': 'Invoice information:',
		'01347': 'Please fill in the header name',
		'01348': 'Please fill in the taxpayer identification number',
		'01349': 'Please fill in the mailing address',
		'01350': 'Please fill in the email address',
		'01351': 'Invoice content:',
		'01352': 'Product',
		'01353': 'Price',
		'01354': 'Quantity',
		'01355': 'Subtotal',
		'01356': 'Coupons',
		'01357': 'Coupons have been selected and discounted',
		'01358': 'Do not use coupons',
		'01359': 'Buyer message:',
		'01360': 'It is recommended to coordinate with the merchant before leaving a message',
		'01361': 'Product amount:',
		'01362': 'Shipping:',
		'01363': 'Tax:',
		'01364': 'Invoice mailing fee:',
		'01365': 'Offer:',
		'01366': 'Points:',
		'01367': 'Coupon:',
		'01368': 'Balance used:',
		'01369L': 'A total of ',
		'01369R': ' items, the amount payable:',
		'01370': 'Order Settlement',
		'01371': 'Select Coupon',
		'01372': 'Name',
		'01373': 'Offer',
		'01374': 'use',
		'01375L': 'Coupon can use for orders over ',
		'01375R': '',
		'01376': 'No amount limit',
		'01377': 'Duration',
		'01378': 'Cancel',
		'01379': 'Confirm selection',
		'01380': 'Select store',
		'01381': 'Name',
		'01382': 'Address',
		'01383': 'Business hours',
		'01384': 'Balance used',
		'01385': 'For your account security, please set the payment password first',
		'01386': 'Can be set in "Member Center" > "Account Security" > "Payment Password"',
		'01387': 'Not set yet',
		'01388': 'Set now',
		'01389': 'Payment password',
		'01390': 'Forgot password',
		'01391': 'Please enter your phone number',
		'01392': 'Mobile phone number format error',
		'01393': 'Please enter the consignee',
		'01394': 'The length is between 1 and 20 characters',
		'01395': 'Please select a state',
		'01396': 'Please select a city',
		'01397': 'Please select a district',
		'01398': 'Please enter the detailed address',
		'01399': 'Select delivery time',
		'01400': 'Please select the correct state',
		'01401': 'Please select the correct city',
		'01402': 'Please select the district correctly',
		'01403': 'Please select a state',
		'01404': 'Please select a city',
		'01405': 'Please select a district',
		'01406': 'No order data! ',
		'01407': 'No order data! ',
		'01408': 'Shipping method',
		'01409': 'Logistics delivery',
		'01410': 'all day',
		'01411': 'all day',
		'01412': 'Sunday',
		'01413': 'Monday',
		'01414': 'Tuesday',
		'01415': 'Wednesday',
		'01416': 'Thursday',
		'01417': 'Friday',
		'01418': 'Saturday',
		'01419': 'to',
		'01420': 'The store has not set delivery time',
		'01421': 'The store has not opened the delivery time',
		'01422': 'Please fill in the title of the invoice',
		'01423': 'Please fill in the taxpayer identification number',
		'01424': 'Please fill in the invoice mailing address',
		'01425': 'Please fill in your email address',
		'01426': 'Please fill in the correct email address',
		'01427': 'Please select the invoice content',
		'01428': 'Store Pickup',
		'01429': 'Logistics delivery',
		'01430': 'Cancel',
		'01431': 'Confirm',
		'01432': 'Order submission...',
		'01433': 'Please enter your cell phone number',
		'01434': 'Please enter the correct cell phone number',
		'01435': 'Please select your shipping address first',
		'01436': 'Shop"',
		'01437': '"Shipping method not set',
		'01438': 'Shop"',
		'01439': '"There is no store that can pick up the goods, please choose another delivery method',
		'01440': 'Please choose a self-pickup store',
		'01441': 'Payment in progress',
		'01442': 'Special event details:',
		'01443': 'Product details:',
		'01444': 'Product information',
		'01445': 'Price',
		'01446': 'Quantity',
		'01447L': 'Insufficient stock, ',
		'01447R': ' pieces remaining',
		'01448': 'Purchase Quantity:',
		'01449': 'Save for you: ',
		'01450': 'Package price:',
		'01451': 'Buy Now',
		'01452': 'Shipping address',
		'01453': 'Add delivery address',
		'01454': 'Edit',
		'01455': 'Are you sure you want to delete this address? ',
		'01456': 'Delete',
		'01457': 'More shipping addresses',
		'01458': 'You need to fill in your mobile phone number to buy virtual goods',
		'01459': 'Cell phone number',
		'01460': 'Please enter your cell phone number',
		'01461': '\'Add shipping address\'',
		'01462': '\'Edit shipping address\'',
		'01463': 'Name',
		'01464': 'Consignee Name',
		'01465': 'Mobile phone',
		'01466': 'Consignee phone number',
		'01467': 'Phone',
		'01468': 'Consignee\'s landline (optional)',
		'01469': 'Region',
		'01470': 'Please select a state',
		'01471': 'Please select a state',
		'01472': 'Please select a city',
		'01473': 'Please select a city',
		'01474': 'Please select a district',
		'01475': 'Please select a district',
		'01476': 'Detailed address',
		'01477': 'Streets, Office buildings',
		'01478': 'Cancel',
		'01479': 'OK',
		'01480': 'Whether to use the balance',
		'01481': 'Do not use balance',
		'01482': 'Balance used',
		'01483': 'Shipping method',
		'01484': 'Please add delivery address first',
		'01485': 'The merchant has not configured a shipping method',
		'01486': 'Product',
		'01487': 'Price',
		'01488': 'Quantity',
		'01489': 'Activity',
		'01490': 'Bundle package ',
		'01491': 'Sales ',
		'01492': 'Buyer message:',
		'01493': 'It is recommended to coordinate with the merchant before leaving a message',
		'01494': 'Product amount:',
		'01495': 'Shipping:',
		'01496': 'Tax:',
		'01497': 'Balance used:',
		'01498L': 'A total of ',
		'01498R': ' items, the amount payable:',
		'01499': 'Order Settlement',
		'01500': 'Select store',
		'01501': 'Name',
		'01502': 'Address',
		'01503': 'Business hours',
		'01504': 'Select platform coupon',
		'01505': 'Name',
		'01506': 'Offer',
		'01507': 'use',
		'01508L': 'Coupon can use for orders over ',
		'01508R': '',
		'01509': 'Can use for all orders',
		'01510': 'Expired time ',
		'01511': 'Cancel',
		'01512': 'Confirm selection',
		'01513': 'Select Coupon',
		'01514': 'Name',
		'01515': 'Offer',
		'01517': 'Use',
		'01518': 'Expired time ',
		'01519': 'Cancel',
		'01520': 'Confirm selection',
		'01521': 'Delivery time',
		'01522': 'Delivery time',
		'01523': 'Select time',
		'01524': 'Close',
		'01525': 'Confirm selection',
		'01526': 'Use balance',
		'01527': 'For your account security, please set the payment password first',
		'01528': 'It can be set in "Member Center" > "Account Security" > "Payment Password"',
		'01529': 'Not set now',
		'01530': 'Set now',
		'01531': 'Payment password',
		'01532': 'Forgot password',
		'01533': 'Please enter your phone number',
		'01534': 'Mobile phone number format error',
		'01535': 'Please enter the consignee',
		'01536': 'The length should between 1 and 20 characters',
		'01537': 'Please select a state',
		'01538': 'Please select a city',
		'01539': 'Please select a district',
		'01540': 'Please enter the detailed address',
		'01541': 'Select delivery time',
		'01542': 'Please select a state',
		'01543': 'Please select a city',
		'01544': 'Please select a district',
		'01545': 'Please select a state',
		'01546': 'Please select a city',
		'01547': 'Please select a district',
		'01548': 'Can not get the data required to create an order! ',
		'01549': 'Can not get the data required to create an order! ',
		'01550': 'Shipping method',
		'01551': 'Logistics delivery',
		'01552': 'Coupon can use for orders over ',
		'01553': '',
		'01554': 'Can use for all orders',
		'01555': 'Order submission...',
		'01556': 'Please select your shipping address first',
		'01557': 'The store has not set the shipping method',
		'01558': 'The store does not have a store that can pick up the goods, please choose another delivery method',
		'01559': 'Payment in progress',
		'01560': 'Group buying in progress',
		'01561': 'Group buying price',
		'01562': 'There are currently no products in group buying, go to the home page to have a look',
		'01563': 'Previous',
		'01564': 'Next',
		'01565': 'The group buying plugin is not installed',
		'01566': 'The group buying plugin is not installed',
		'01567': 'Group purchase product',
		'01568': 'Time to the event end ',
		'01569': 'This video is temporarily unavailable, please try again later',
		'01570': 'The group buying plugin is not installed',
		'01571': 'The group buying plugin is not installed',
		'01572': 'The group buying plugin is not installed',
		'01573': 'Event has ended',
		'01574': 'Pieces',
		'01575': 'Manila',
		'01576': 'Manila',
		'01577': 'Manila',
		'01578': 'Manila',
		'01579': 'Tondo',
		'01580': 'Tondo',
		'01581': 'You are not logged in',
		'01582': 'Event has ended',
		'01583': 'Item is sold out',
		'01584': 'The purchase quantity cannot be 0',
		'01585': 'Event has ended',
		'01586': 'The group buying activity has ended',
		'01587': 'Video',
		'01588': 'Image',
		'01589': 'Favorite products',
		'01590': 'Contact customer service',
		'01591': 'Contact customer service',
		'01592': 'Group buying',
		'01593': '\'days\'',
		'01594': '\'hour\'',
		'01595': '\'minute\'',
		'01596': 'seconds',
		'01597': 'Group price',
		'01598': 'Original price',
		'01599': 'Cumulative evaluation',
		'01600': 'Cumulative sales',
		'01601': 'Shipping',
		'01602': 'Express Free Shipping',
		'01603': 'Express delivery is not free',
		'01604': 'Ship to',
		'01605': 'Please select a shipping address',
		'01606': '\'Please select a state\'',
		'01607': '\'Please select a city\'',
		'01608': '\'Please select a district\'',
		'01609': 'Service ',
		'01610': ' by ',
		'01611': ' shipping and providing after-sales service',
		'01612': 'Quantity',
		'01613': 'In stock ',
		'01614L': 'Minimum purchase ',
		'01614R': '',
		'01615': 'Out of stock',
		'01616': 'Buy now',
		'01617': 'QR code image',
		'01618': 'This product is no longer available',
		'01619': 'QR code image',
		'01620': 'Product service',
		'01621': 'Product Details',
		'01622': 'Product attributes',
		'01623': '\'Product review (\'',
		'01624': '\'Product review\'',
		'01625': 'All comments',
		'01626': 'Positive comments',
		'01627': 'Neutral comments',
		'01628': 'Negative comments',
		'01629': 'Response from the store:',
		'01630': 'Additional evaluation',
		'01631': 'Response from the store:',
		'01632': 'Previous',
		'01633': 'Next',
		'01634': 'There are no reviews for this product yet',
		'01635': 'After-sale guarantee',
		'01636': 'Shipping address',
		'01637': 'Add shipping address',
		'01638': 'Edit',
		'01639': 'Are you sure you want to delete this address? ',
		'01640': 'Delete',
		'01641': 'More shipping addresses',
		'01642': 'You need to fill in your mobile phone number when purchasing virtual goods',
		'01643': 'Mobile phone number',
		'01644': 'Please enter your mobile phone number',
		'01645': '\'Add shipping address\'',
		'01646': '\'Edit delivery address\'',
		'01647': 'Name',
		'01648': 'Consignee Name',
		'01649': 'Mobile phone',
		'01650': 'Consignee phone number',
		'01651': 'Phone',
		'01652': 'Consignee\'s landline (optional)',
		'01653': 'Region',
		'01654': 'Please select a state',
		'01655': 'Please select a state',
		'01656': 'Please select a city',
		'01657': 'Please select a city',
		'01658': 'Please select a district',
		'01659': 'Please select a district',
		'01660': 'Detailed address',
		'01661': 'Streets, office buildings',
		'01662': 'Cancel',
		'01663': 'OK',
		'01664': 'Whether to use the balance',
		'01665': 'Do not use balance',
		'01666': 'Balance used',
		'01667': 'Shipping Method',
		'01668': 'Please add delivery address first',
		'01669': 'The merchant has not configured a shipping method',
		'01670': 'Product',
		'01671': 'Price',
		'01672': 'Quantity',
		'01673': 'Shop promotions',
		'01674L': 'When purchase quantity reaches ',
		'01674R': ', you can get the group buying price at ',
		'01675': 'Buyer message',
		'01676': 'It is recommended to coordinate with the merchant before leaving a message',
		'01677': 'Product amount:',
		'01678': 'Shipping:',
		'01679': 'Tax:',
		'01680': 'Offer:',
		'01681': 'Balance used:',
		'01682L': 'A total of ',
		'01682R': ' items, the amount payable:',
		'01683': 'Order Settlement',
		'01684': 'Select store',
		'01685': 'Name',
		'01686': 'Address',
		'01687': 'Business hours',
		'01688': 'Delivery time',
		'01689': 'Delivery time',
		'01690': 'Select time',
		'01691': 'Close',
		'01692': 'Confirm selection',
		'01693': 'Balance used',
		'01694': 'For your account security, please set the payment password first',
		'01695': 'Can be set in "Member Center" > "Account Security" > "Payment Password"',
		'01696': 'Do not set now',
		'01697': 'Set now',
		'01698': 'Payment password',
		'01699': 'Forgot password',
		'01700': 'Please enter your phone number',
		'01701': 'Mobile phone number format error',
		'01702': 'Please enter the consignee',
		'01703': 'The length is between 1 and 20 characters',
		'01704': 'Please select a state',
		'01705': 'Please select a city',
		'01706': 'Please select a district',
		'01707': 'Please enter the detailed address',
		'01708': 'Select delivery time',
		'01709': 'Please select a state',
		'01710': 'Please select a city',
		'01711': 'Please select a district',
		'01712': 'Please select a state',
		'01713': 'Please select a city',
		'01714': 'Please select a district',
		'01715': 'Please select the correct state',
		'01716': 'Please select the correct city',
		'01717': 'Please select the district correctly',
		'01718': 'Please select a state',
		'01719': 'Please select a city',
		'01720': 'Please select a district',
		'01721': 'Can not get the data required to create an order! ',
		'01722': 'Can not get the data required to create an order! ',
		'01723': 'Shipping Method',
		'01724': 'Logistics delivery',
		'01725': 'Order submission...',
		'01726': 'Please enter your mobile phone number',
		'01727': 'Please enter the correct mobile number',
		'01728': 'Please select your shipping address first',
		'01729': 'Shop"',
		'01730': '"Shipping method not set"',
		'01731': 'Shop"',
		'01732': '"There is no store that can pick up the goods, please choose another delivery method',
		'01733': 'Please choose a self-pickup store',
		'01734': 'Payment in progress',
		'01735': 'Time to the event end ',
		'01736': 'This video is temporarily unavailable, please try again later',
		'01737': 'The seckill plugin is not installed',
		'01738': 'The seckill plugin is not installed',
		'01739': 'The seckill plugin is not installed',
		'01740': 'The flash sale event has not started yet',
		'01741': 'The flash sale event has ended',
		'01742': 'Pieces',
		'01743': 'Manila',
		'01744': 'Manila',
		'01745': 'Manila',
		'01746': 'Manila',
		'01747': 'Tondo',
		'01748': 'Tondo',
		'01749': 'You are not logged in',
		'01750': 'Item is sold out',
		'01751': 'The purchase quantity cannot be 0',
		'01752': 'Event has ended',
		'01753': 'The flash sale event has ended',
		'01754': 'Video',
		'01755': 'Image',
		'01756': 'Favorite goods ',
		'01757': 'Contact customer service',
		'01758': 'Contact customer service',
		'01759': 'Flash sale event',
		'01760': '\'days\'',
		'01761': '\'hour\'',
		'01762': '\'minutes\'',
		'01763': '\'seconds\'',
		'01764': 'Sunkill price',
		'01765': 'Original price',
		'01766': 'Cumulative reviews',
		'01767': 'Cumulative sales',
		'01768': 'Shipping fee',
		'01769': 'Free Shipping',
		'01770': 'Non-free shipping',
		'01771': 'Ship to',
		'01772': 'Please select a shipping address',
		'01773': '\'Please select a state\'',
		'01774': '\'Please select a city\'',
		'01775': '\'Please select a district\'',
		'01776': 'Service',
		'01777': ' by ',
		'01778': ' shipping and providing after-sales service',
		'01779': 'Quantity',
		'01780': 'Inventory',
		'01781': 'Buy now',
		'01782': 'Out of stock',
		'01783': 'QR code',
		'01784': 'This product is no longer available',
		'01785': 'QR code',
		'01786': 'Product service',
		'01787': 'Product Details',
		'01788': 'Product attributes',
		'01789': '\'Product reviews (\'',
		'01790': '\'Product review\'',
		'01791': 'All comments',
		'01792': 'Positive comments',
		'01793': 'Neutral comments',
		'01794': 'Negative comments',
		'01795': 'Response from the store:',
		'01796': 'Additional comments',
		'01797': 'Response from the store:',
		'01798': 'Previous',
		'01799': 'Next',
		'01800': 'No reviews for this product yet',
		'01801': 'After-sale guarantee',
		'01802': 'Shipping address',
		'01803': 'Add shipping address',
		'01804': 'Edit',
		'01805': 'Are you sure you want to delete this address? ',
		'01806': 'Delete',
		'01807': 'More shipping addresses',
		'01808': 'You need to fill in your mobile phone number to buy virtual goods',
		'01809': 'Mobile phone number',
		'01810': 'Please enter your mobile phone number',
		'01811': '\'Add shipping address\'',
		'01812': '\'Edit delivery address\'',
		'01813': 'Name',
		'01814': 'Consignee Name',
		'01815': 'Mobile phone',
		'01816': 'Consignee phone number',
		'01817': 'Phone',
		'01818': 'Consignee\'s landline phone number (optional)',
		'01819': 'Region',
		'01820': 'Please select a state',
		'01821': 'Please select a state',
		'01822': 'Please select a city',
		'01823': 'Please select a city',
		'01824': 'Please select a district',
		'01825': 'Please select a district',
		'01826': 'Detailed address',
		'01827': 'Streets, office buildings',
		'01828': 'Cancel',
		'01829': 'OK',
		'01830': 'Whether to use the balance',
		'01831': 'Do not use balance',
		'01832': 'Balance used',
		'01833': 'Shipping Method',
		'01834': 'Please add delivery address first',
		'01835': 'The merchant has not configured a shipping method',
		'01836': 'Product',
		'01837': 'Price',
		'01838': 'Quantity',
		'01839': 'Shop promotions',
		'01840': 'Flash Sale',
		'01841': 'Buyer message',
		'01842': 'It is recommended to coordinate with the merchant before leaving a message',
		'01843': 'Product amount:',
		'01844': 'Shipping:',
		'01845': 'Tax:',
		'01846': 'Offer:',
		'01847': 'Balance used:',
		'01848L': 'A total of ',
		'01848R': ' items, the amount payable:',
		'01849': 'Order Settlement',
		'01850': 'Select store',
		'01851': 'Name',
		'01852': 'Address',
		'01853': 'Business hours',
		'01854': 'Delivery time',
		'01855': 'Delivery time',
		'01856': 'Select time',
		'01857': 'Off',
		'01858': 'Confirm selection',
		'01859': 'Balance used',
		'01860': 'For your account security, please set the payment password first',
		'01861': 'Can be set in "Member Center" > "Account Security" > "Payment Password"',
		'01862': 'Do not set yet',
		'01863': 'Set now',
		'01864': 'Payment password',
		'01865': 'Forgot password',
		'01866': 'Please enter your phone number',
		'01867': 'Mobile phone number format error',
		'01868': 'Please enter the consignee',
		'01869': 'The length is between 1 and 20 characters',
		'01870': 'Please select a state',
		'01871': 'Please select a city',
		'01872': 'Please select a district',
		'01873': 'Please enter the detailed address',
		'01874': 'Select delivery time',
		'01875': 'Please select a state',
		'01876': 'Please select a city',
		'01877': 'Please select a district',
		'01878': 'Please select a state',
		'01879': 'Please select a city',
		'01880': 'Please select a district',
		'01881': 'Please select the correct state',
		'01882': 'Please select the correct city',
		'01883': 'Please select the district correctly',
		'01884': 'Please select a state',
		'01885': 'Please select a city',
		'01886': 'Please select a district',
		'01887': 'Can not get the data required to create an order! ',
		'01888': 'Can not get the data required to create an order! ',
		'01889': 'Shipping Method',
		'01890': 'Logistics delivery',
		'01891': 'Order submission...',
		'01892': 'Please enter your mobile phone number',
		'01893': 'Please enter the correct mobile number',
		'01894': 'Please select your shipping address first',
		'01895': 'Shop"',
		'01896': '"Shipping method not set"',
		'01897': 'Shop"',
		'01898': '"There is no store that can pick up the goods, please choose another delivery method',
		'01899': 'Please choose a self-pickup store',
		'01900': 'Payment in progress',
		'01901': 'Stay tuned',
		'01902': 'coming soon',
		'01903': 'Purchasing',
		'01904': 'Ended',
		'01905': '\'Stay tuned\'',
		'01906': 'Flash Sale price',
		'01907': 'Buy now',
		'01908': 'Previous',
		'01909': 'Next',
		'01910': 'There are currently no products that are in the process of flash sales, go to the homepage to have a look',
		'01911': 'Time to the event end ',
		'01912': 'The seckill plugin is not installed',
		'01913': 'The seckill plugin is not installed',
		'01914': 'Flash Sale event has ended',
		'01915': 'Event has ended',
		'01916': 'Flash Sale event Product',
		'01917': 'The Flash Sale event has not yet started, please wait! ',
	},
	
}
