// usage: 
// {{ $lang('module.text', $route) }}
export const lang = {
	common: {
		name: '中文',
	},
	// {{ $lang('components.currency_symbol_left', $route) }}
	// {{ $lang('components.currency_symbol_right', $route) }}
	components: {
		'index': '首页',
		'goods_recommend': '商品精选',
		'currency_symbol': '￥',
		'currency_symbol_left': '￥',
		'currency_symbol_right': '元',
		'discount_left' : '打',
		'discount_right' : '折',
	},
	http: {
		'login_error': '登录错误',
		'params_must_array': '参数必须为数组',
	},
	cargo: {
		'promotions_name': '活动名称',
		'sale_num': '人购买',
		'stock_num': '库存剩余',
		'self_operated': '自营',
		'free_shipping': '包邮',
		'virtual_product': '虚拟商品',
	},
	index: {
		'coupon_center': '领券中心',
		'more': '更多',
	},
	header: {
		'please_login': '您好，请登录',
		'please_register': '请注册',
		'login_with': '登录',
		'forgot_password': '忘记密码',
		'forgot_password_email': 'Forgot password email',
		'reset_password': '重置密码',
		'logout': '退出',
		'login': '登录',
		'register': '免费注册',
		'my_order': '我的订单',
		'my_visit': '我的足迹',
		'my_collection': '我的关注',
		'help_center': '帮助中心',
		'notice': '公告',
		'about': 'About',
		'shop': '手机商城',
	},
	orderMethod: {
		'payment_adjusted': '商家已将支付金额调整为',
		'continue_pay': '元，是否继续支付？',
		'prompt': '提示',
		'confirm': '确定',
		'cancel': '取消',
		'close_order_confirm': '您确定要关闭该订单吗？',
		'close_order_success': '订单关闭成功',
		'order_delivery_ok': '您确定已经收到货物了吗？',
		'order_delivery_done': '订单收货成功',
	},
	util: {
		'sun': '周日',
		'mon': '周一',
		'tue': '周二',
		'wed': '周三',
		'thu': '周四',
		'fri': '周五',
		'sat': '周六',
		'copy_success': '复制成功',
		'obj_is_not_an_object': 'obj 不是一个对象！',
	},
	goodsItem: {
		'stock_num': '库存：',
		'sale_num': '销量：',
		'jump_good': '查看商品',
		'goods_sku': '商品ID',
	},
	orderItem: {
		'order_status': '订单状态：',
		'order_num': '订单号：12345678',
		'jump_order': '查看订单',
		'order_detail': '订单详情',
	},
	serviceMessage: {
		'onsultation': '客服咨询',
		'no_history': '没有聊天记录了',
		'click_to_load': '点击加载',
		'service_online': '客服在线',
		'service_not_online': '客服不在线',
		'send': '发送',
		'today': '今天',
		'tomorrow': '明天',
		'yesterday': '昨天',
		'new_version': '新版本',
		'switch_contacts': '切换联系人',
		'max_words': '最多一次可以发送150个字',
		'no_blank': '不能发送空白内容',
		'socket_open': '连接建立',
		'reset_hb': '检测心跳',
		'start_hb': '启动心跳',
		'pinging': '检测...',
		'close_socket': '断开连接',
		'close_dialog': '关闭链接',
	},
	CopyRight: {
		'icp_num': '备案号：',
		'ga_num': '公安备案',
	},
	NsAside: {
		'my_cart': '购物车',
		'my_orders': '我的订单',
		'my_footprint': '我的足迹',
		'my_collection': '我的关注',
		'my_coupon': '我的优惠券',
		'service': '客服',
		'go_top': '返回顶部',
		'site_service': '平台客服',
		'not_login': '您还未登录',
	},
	NsFooter: {
		'links': '友情链接',
		'service_phone': '服务热线',
		'wx_microprogram': '微信小程序',
	},
	NsHeader: {
		'all_category': '所有商品分类',
	},
	NsHeaderMid: {
		'goods': '商品',
		'search': '搜索',
		'hot_search': '热门搜索：',
		'my_cart': '我的购物车',
		'newly_added': '最新加入的商品',
		'delete': '删除',
		'total': '共',
		'products': '种商品',
		'total_amount': '总金额',
		'goto_cart': '去购物车',
		'empty_cart': '您的购物车是空的，赶快去逛逛，挑选商品吧！',
		'good': '商品',
		'shop': '店铺',
		'unit_price': '单价',
	},
	ShopHeader: {
		'welcome1': 'Welcome to ',
		'welcome2': '欢迎您',
		'all_category': '所有商品分类',
		'shop_home': '店铺首页',
		'all_goods': '全部商品',
		'category_menu': '分类菜单',
	},
	pay_index: {
		'pay_success': '您的订单已提交成功，正在等待处理！',
		'amounts_topay': '应付金额：',
		'order_info': '订单信息',
		'trade_num': '交易单号：',
		'pay_detail': '订单内容：',
		'pay_amount': '订单金额：',
		'create_time': '创建时间：',
		'pay_method': '支付方式',
		'no_pay_method': '商家未配置支付方式',
		'pay_now': '立即支付',
		'pay_confirm': '请确认支付是否完成',
		'pay_nextstep_info': '完成支付前请根据您的情况点击下面的按钮',
		'back_home': '返回首页',
		'pay_done': '已完成支付',
		'wxpay_qrcode': '请用微信扫码支付',
		'pay_by_alipay': '支付宝支付',
		'pay_by_wechat': '微信支付',
	},
	pay_result: {
		'payment_successful': '支付成功',
		'payment_failed': '支付失败',
		'payment_amount': '支付金额：',
		'member_centre': '会员中心',
		'back_home': '回到首页',
	},
	
	tmdDOTco: {
		'00200': '未获取到支付信息',
		'00201': '首页',
		'00202': '帮助列表',
		'00203': '帮助详情',
		'00204': '首页',
		'00205': '帮助',
		'00206': '帮助列表',
		'00207': '首页',
		'00208': '公告列表',
		'00209': '公告详情',
		'00210': '首页',
		'00211': '公告',
		'00212': '最新公告',
		'00213': '商城公告',
		'00214': '上一页',
		'00215': '下一页',
		'00216': '用户注册',
		'00217': '用户名',
		'00218': '请输入用户名',
		'00219': '密码',
		'00220': '请输入密码',
		'00221': '确认密码',
		'00222': '请输入确认密码',
		'00223': '验证码',
		'00224': '请输入验证码',
		'00225': '阅读并同意',
		'00226': '《服务协议》',
		'00227': '已有账号，立即登录',
		'00228': '立即注册',
		'00229': '手机动态码注册',
		'00230': '请输入手机号',
		'00231': '请输入验证码',
		'00232': '请输入短信动态码',
		'00233': '阅读并同意',
		'00234': '《服务协议》',
		'00235': '已有账号，立即登录',
		'00236': '立即注册',
		'00237': '积分奖励',
		'00238': '积分',
		'00239': '用于下单时抵现或兑换商品等',
		'00240': '立即查看',
		'00241': '成长值',
		'00242': '成长值',
		'00243': '用于提升会员等级',
		'00244': '立即查看',
		'00245': '红包奖励',
		'00247': '不可提现, 下单时可用',
		'00248': '立即查看',
		'00249': '优惠券奖励',
		'00250': '满',
		'00252L': '减',
		'00252R': '',
		'00255L': '减',
		'00255R': '',
		'00256': '立即查看',
		'00257': '请再次输入密码',
		'00258': '两次输入密码不一致!',
		'00259': '请输入密码',
		'00260': '密码长度不能小于',
		'00261': '位',
		'00262': '密码需包含',
		'00263': '数字',
		'00264': '、小写字母',
		'00265': '、大写字母',
		'00266': '、特殊字符',
		'00267': '手机号不能为空',
		'00268': '请输入正确的手机号',
		'00269': '请输入用户名',
		'00270': '请输入验证码',
		'00271': '请输入验证码',
		'00272': '请输入短信动态码',
		'00273': '获取动态码',
		'00274L': '',
		'00274R': '秒后可重新获取',
		'00275': '平台未启用普通注册注册',
		'00276': '平台未启用注册',
		'00277': '请先阅读协议并勾选',
		'00278': '用户名只能输入数字跟英文',
		'00279': '请先阅读协议并勾选',
		'00280': '手机号不能为空',
		'00281': '请输入正确的手机号',
		'00282': '请输入新的登录密码',
		'00283': '密码长度不能小于',
		'00284': '位',
		'00285': '密码需包含',
		'00286': '数字',
		'00287': '、小写字母',
		'00288': '、大写字母',
		'00289': '、特殊字符',
		'00290': '请输入确认密码',
		'00291': '两次密码输入不一致',
		'00292': '获取动态码',
		'00293': '请输入验证码',
		'00294': '请输入短信动态码',
		'00295': '获取动态码',
		'00296': '该手机号未注册',
		'00297L': '',
		'00297R': '秒后可重新获取',
		'00298': '输入手机号',
		'00299': '验证身份',
		'00300': '重置密码',
		'00301': '完成',
		'00302': '请输入注册手机号',
		'00303': '下一步',
		'00304': '请输入验证码',
		'00305': '请输入短信动态码',
		'00306': '下一步',
		'00307': '请输入新的登录密码',
		'00308': '请再次输入新密码',
		'00309': '重置密码',
		'00310': '重置密码成功',
		'00311': '重新登录',
		'00312': '账号登录',
		'00313': '请输入账号',
		'00314': '请输入登录密码',
		'00315': '请输入验证码',
		'00316': '七天自动登录',
		'00317': '忘记密码',
		'00318': '登录',
		'00319': '没有账号？',
		'00320': '立即注册',
		'00321': '使用微信扫码登录',
		'00322': '手机动态码登录',
		'00323': '请输入手机号',
		'00324': '请输入验证码',
		'00325': '请输入短信动态码',
		'00326': '登录',
		'00327': '立即注册',
		'00328': '微信扫码登录',
		'00329': '使用账号密码登录',
		'00330': '扫码成功',
		'00331': '请输入手机号',
		'00332': '请输入验证码',
		'00333': '请输入短信动态码',
		'00334': '确定',
		'00335': '使用其他方式登录',
		'00336': '立即注册',
		'00337': '手机号不能为空',
		'00338': '请输入正确的手机号',
		'00339': '获取动态码',
		'00340': '请输入登录账号',
		'00341': '请输入登录密码',
		'00342': '请输入验证码',
		'00343': '请输入验证码',
		'00344': '请输入短信动态码',
		'00345': '请输入验证码',
		'00346': '请输入短信动态码',
		'00347': '请输入验证码',
		'00348': '获取动态码',
		'00349': '登录成功！',
		'00350': '登录成功！',
		'00351': '登录成功！',
		'00352': '登录成功！',
		'00353L': '',
		'00353R': '秒后可重新获取',
		'00354L': '',
		'00354R': '秒后可重新获取',
		'00355': '网站临时关闭',
		'00356': '返回上一页',
		'00357': '返回首页',
		'00358': '折',
		'00359L': '满',
		'00359R': '可用',
		'00360L': '满',
		'00360R': '可用',
		'00361': '立即领取',
		'00362': '去使用',
		'00363': '限时秒杀',
		'00364': '更多商品',
		'00365': '商品',
		'00366': '店铺',
		'00367': '搜索',
		'00368': '我的购物车',
		'00369': '距离结束',
		'00370': '商品',
		'00371': '商品',
		'00372': '店铺',
		'00373': '活动已结束',
		'00374': '获取优惠券列表',
		'00375': '领取优惠券',
		'00376': '领取成功',
		'00377': '领取优惠券',
		'00378': '您尚未登录，请先进行登录',
		'00379': '商品图片',
		'00382': '商品图片',
		'00385': '商品图片',
		'00386': '商品图片',
		'00387': '暂无商品分类',
		'00388': '优惠券中心',
		'00389': '省钱更多，领券更多',
		'00390': '限时抢券',
		'00391': '叠加使用',
		'00392': '种类多样',
		'00393': '我的优惠券',
		'00394': '折',
		'00395': '\'全部商品可用\'',
		'00396': '\'指定商品可用\'',
		'00397': '无门槛优惠券',
		'00398L': '满',
		'00398R': '可用',
		'00399L': '领取之日起',
		'00399R': '日内有效',
		'00400': '有效期至',
		'00401': '已抢完',
		'00402': '立即领取',
		'00403': '去使用',
		'00404': '暂无优惠券',
		'00405': '上一页',
		'00406': '下一页',
		'00407': '优惠券插件未安装',
		'00408': '优惠券插件未安装',
		'00409': '领取成功',
		'00410': '您尚未登录，请先进行登录',
		'00411': '确定要删除该商品吗？',
		'00412': '提示信息',
		'00413': '确定',
		'00414': '取消',
		'00415': '删除成功',
		'00416': '请选择要删除的商品',
		'00417': '确定要删除选择的商品吗？',
		'00418': '提示信息',
		'00419': '确定',
		'00420': '取消',
		'00421': '删除成功',
		'00422': '确认要清空这些商品吗？',
		'00423': '提示信息',
		'00424': '确定',
		'00425': '取消',
		'00426': '删除成功',
		'00427': '商品信息',
		'00428': '价格',
		'00429': '数量',
		'00430': '小计',
		'00431': '操作',
		'00432': '删除',
		'00433': '失效商品',
		'00434': '件',
		'00435': '失效',
		'00436': '全选',
		'00437': '删除',
		'00438': '清除失效宝贝',
		'00439': '已选商品',
		'00440': '件',
		'00441': '合计(不含运费)：',
		'00442': '结算',
		'00443': '结算',
		'00444': '您的购物车是空的，赶快去逛逛，挑选商品吧！',
		'00445': '距离结束仅剩',
		'00446': '此视频暂无法播放，请稍后再试',
		'00447': '您还未登录',
		'00448': '所有信息',
		'00449': '件',
		'00450': '北京市',
		'00451': '北京',
		'00452': '北京市',
		'00453': '北京',
		'00454': '东城区',
		'00455': '东城',
		'00456': '您尚未登录，请先进行登录',
		'00457': '您尚未登录，请先进行登录',
		'00459': '件',
		'00460': '满',
		'00461': '减',
		'00462': '元',
		'00463': '；满',
		'00464': '减',
		'00465': '元',
		'00466': '送',
		'00467': '积分',
		'00468': '送',
		'00469': '张',
		'00470': '折优惠券',
		'00471': '、送',
		'00472': '张',
		'00473': '折优惠券',
		'00474': '送',
		'00475': '张',
		'00476': '元优惠券',
		'00477': '、送',
		'00478': '张',
		'00479': '元优惠券',
		'00480': '满',
		'00481': '满',
		'00482': '满',
		'00483': '包邮',
		'00484': '；满',
		'00485': '包邮',
		'00486': '领取成功',
		'00487': '商品已售罄',
		'00488': '购买数量不能为0',
		'00489': '加入购物车成功',
		'00490': '您尚未登录，请先进行登录',
		'00491': '商品已售罄',
		'00492': '购买数量不能为0',
		'00493': '商品限购',
		'00494': '件，现在已经购买',
		'00495': '件,还能购买',
		'00496': '件',
		'00497': '活动已结束',
		'00498': '视频',
		'00499': '图片',
		'00500': '关注商品',
		'00501': '联系客服',
		'00502': '联系客服',
		'00503': '限时折扣',
		'00504': '\'天\'',
		'00505': '\'小时\'',
		'00506': '\'分钟\'',
		'00507': '\'秒\'',
		'00508': '销售价',
		'00509': '原价',
		'00510': '会员价',
		'00511': '市场价',
		'00512': '累计评价',
		'00513': '累计销量',
		'00514': '优惠券',
		'00517': '满减',
		'00518': '满送',
		'00519': '运费',
		'00520': '快递免邮',
		'00521': '快递不免邮',
		'00522': '配送至',
		'00523': '请选择配送地址',
		'00524': '请选择省',
		'00525': '请选择市',
		'00526': '请选择区/县',
		'00527': '服务',
		'00528': '由',
		'00529': '发货并提供售后服务',
		'00530': '数量',
		'00531': '库存',
		'00532': '库存不足',
		'00533': '已达最大限购数量',
		'00534': '立即购买',
		'00535': '加入购物车',
		'00536': '该商品已下架',
		'00537': '二维码图片',
		'00538': '该商品已下架',
		'00539': '二维码图片',
		'00540': '商品服务',
		'00541': '组合套餐价',
		'00542': '立即购买',
		'00543': '商品详情',
		'00544': '商品属性',
		'00545': '\'商品评价\'',
		'00546': '\'商品评价\'',
		'00547': '全部评价',
		'00548': '好评',
		'00549': '中评',
		'00550': '差评',
		'00551': '店家回复：',
		'00552': '追加评价',
		'00553': '店家回复：',
		'00554': '上一页',
		'00555': '下一页',
		'00556': '该商品暂无评价哦',
		'00557': '首页',
		'00558': '首页',
		'00559': '分类：',
		'00560': '全部',
		'00561': '多选',
		'00562': '综合',
		'00563': '销量',
		'00564': '价格',
		'00565': '包邮',
		'00566': '最低价格',
		'00567': '最高价格',
		'00568': '确定',
		'00569': '人付款',
		'00570': '包邮',
		'00571': '限时折扣',
		'00572': '上一页',
		'00573': '下一页',
		'00574': '没有找到您想要的商品。换个条件试试吧',
		'00575': '商品分类搜索',
		'00576': '加入购物车成功',
		'00577': '收藏成功',
		'00578': '取消收藏成功',
		'00579': '我的可用余额',
		'00580': '提现',
		'00581': '充值',
		'00582': '可提现余额:',
		'00583': '不可提现余额:',
		'00584': '来源',
		'00585': '金额',
		'00586': '详细说明',
		'00587': '时间',
		'00588': '上一页',
		'00589': '下一页',
		'00590': '编辑账户',
		'00591': '姓名',
		'00592': '请输入真实姓名',
		'00593': '手机',
		'00594': '请输入手机号',
		'00595': '账号类型',
		'00596': '请选择账号类型',
		'00597': '银行名称',
		'00598': '请输入银行名称',
		'00599': '提现账号',
		'00600': '请输入提现账号',
		'00601': '保存',
		'00602': '手机号不能为空',
		'00603': '请输入正确的手机号',
		'00604': '请输入真实姓名',
		'00605': '请选择账号类型',
		'00606': '请输入银行名称',
		'00607': '请输入提现账号',
		'00608': '账户列表',
		'00609': '+ 新增账户',
		'00610': '默认',
		'00611': '手机号码：',
		'00612': '提现账号：',
		'00613': '账号类型：',
		'00614': '银行名称：',
		'00615': '设为默认',
		'00616': '编辑',
		'00617': '删除',
		'00618': '上一页',
		'00619': '下一页',
		'00620': '银行',
		'00621': '支付宝',
		'00622': '微信',
		'00623': '修改默认账户成功',
		'00624': '确定要删除该账户吗?',
		'00625': '提示',
		'00626': '确定',
		'00627': '取消',
		'00628': '编辑收货地址',
		'00629': '姓名',
		'00630': '收货人姓名',
		'00631': '手机',
		'00632': '收货人手机号',
		'00633': '电话',
		'00634': '收货人固定电话（选填）',
		'00635': '地址',
		'00636': '请选择省',
		'00637': '请选择市',
		'00638': '请选择区/县',
		'00639': '详细地址',
		'00640': '定位到小区、街道、写字楼',
		'00641': '是否默认',
		'00642': '是',
		'00643': '否',
		'00644': '保存',
		'00645': '手机号不能为空',
		'00646': '请输入正确的手机号',
		'00647': '请选择区/县',
		'00648': '请选择市',
		'00649': '请选择省',
		'00650': '请输入收货人姓名',
		'00651': '请输入详细地址',
		'00652': '账户余额',
		'00653': '提现申请',
		'00654': '提现到：',
		'00655': '暂不支持微信提现，请选择支付宝',
		'00656': '提现账户：',
		'00657': '请选择提现账户',
		'00658': '提现金额：',
		'00659': '可提现余额为',
		'00660': '全部提现',
		'00661': '最小提现金额',
		'00662': '手续费',
		'00663': '提现',
		'00664': '请先添加提现方式',
		'00665': '请输入提现金额',
		'00666': '提现金额超出可提现金额',
		'00667': '提现金额小于最低提现金额',
		'00668': '提现申请成功！',
		'00669': '宝贝',
		'00670': '上一页',
		'00671': '下一页',
		'00672': '您还没有关注商品哦',
		'00673': '猜你喜欢',
		'00674': '商品图片',
		'00677': '取消关注成功',
		'00678': '我的优惠券',
		'00679': '未使用',
		'00680': '已使用',
		'00681': '已过期',
		'00683L': '满',
		'00683R': '可用',
		'00684': '无门槛优惠券',
		'00685L': '领取之日起',
		'00685R': '日内有效',
		'00686': '有效期至',
		'00687': '上一页',
		'00688': '下一页',
		'00689': '您还没有优惠券哦',
		'00690': '优惠券插件未安装',
		'00691': '优惠券插件未安装',
		'00692': '您还没有优惠券哦',
		'00693': '您还没有使用过优惠券哦',
		'00694': '您还没有过期优惠券哦',
		'00695': '我的足迹',
		'00696': '暂时没有足迹~',
		'00697': '会员中心',
		'00698': '欢迎页',
		'00699': '个人信息',
		'00700': '账户安全',
		'00701': '收货地址',
		'00702': '我的关注',
		'00703': '我的足迹',
		'00704': '交易中心',
		'00705': '我的订单',
		'00706': '退款/售后',
		'00707': '核销台',
		'00708': '核销记录',
		'00709': '账户中心',
		'00710': '账户余额',
		'00711': '提现记录',
		'00712': '我的优惠券',
		'00713': '我的积分',
		'00714': '账户列表',
		'00715': '收货地址',
		'00716': '+ 添加收货地址',
		'00717': '默认',
		'00718': '设为默认',
		'00719': '编辑',
		'00720': '删除',
		'00721': '上一页',
		'00722': '下一页',
		'00723': '修改默认地址成功',
		'00724': '确定要删除该地址吗?',
		'00725': '提示',
		'00726': '确定',
		'00727': '取消',
		'00728': '默认地址，不能删除!',
		'00729': '成长值：',
		'00730': '未登录',
		'00731': '优惠券',
		'00732': '积分',
		'00733': '余额',
		'00734': '我的关注',
		'00735': '商品关注',
		'00736': '我的订单',
		'00737': '待付款',
		'00738': '待发货',
		'00739': '待收货',
		'00740': '待评价',
		'00741': '退款/售后',
		'00742': '查看详情',
		'00743': '您买的东西太少了，这里都空空的，快去挑选合适的商品吧！',
		'00744': '我的足迹',
		'00745': '获取订单数量',
		'00746': '基本信息',
		'00747': '账号',
		'00748': '手机号',
		'00749': '去绑定',
		'00750': '昵称',
		'00751': '保存',
		'00752': '头像照片',
		'00753': '头像预览',
		'00754': '完善个人信息资料，上传头像图片有助于您结识更多的朋友。',
		'00755': '头像最佳默认尺寸为120x120像素。',
		'00756': '保存',
		'00757': '请输入昵称',
		'00758': '最大长度为30个字符',
		'00759': '修改昵称成功',
		'00760': '头像修改成功',
		'00761': '账户余额',
		'00762': '充值套餐列表',
		'00763': '充值套餐详情',
		'00764L': '额外赠送：',
		'00764R': '积分',
		'00765L': '额外赠送',
		'00765R': '成长值',
		'00766': '立即充值',
		'00767': '我的积分',
		'00768': '来源',
		'00769': '积分',
		'00770': '详细说明',
		'00771': '时间',
		'00772': '上一页',
		'00773': '下一页',
		'00774': '账户余额',
		'00775': '充值套餐列表',
		'00776': '我的可用余额',
		'00777': '充值记录',
		'00778': '可提现余额:',
		'00779': '不可提现余额:',
		'00780': '套餐名称',
		'00781': '面值',
		'00782': '赠送积分',
		'00783': '赠送成长值',
		'00784': '操作',
		'00785': '充值',
		'00786': '上一页',
		'00787': '下一页',
		'00788': '账户余额',
		'00789': '充值套餐列表',
		'00790': '充值记录',
		'00791': '套餐名称',
		'00792': '面值',
		'00793': '购买价格',
		'00794': '赠送积分',
		'00795': '赠送成长值',
		'00796': '订单号：',
		'00797': '暂无相关订单',
		'00798': '上一页',
		'00799': '下一页',
		'00800': '提现记录',
		'00801': '账户类型',
		'00802': '提现金额',
		'00803': '提现时间',
		'00804': '提现状态',
		'00805': '操作',
		'00806': '详情',
		'00807': '暂无提现记录',
		'00808': '上一页',
		'00809': '下一页',
		'00810': '提现记录',
		'00811': '提现详情',
		'00812': '当前状态',
		'00813': '交易号',
		'00814': '手续费',
		'00815': '申请时间',
		'00816': '审核时间',
		'00817': '银行名称',
		'00818': '收款账号',
		'00819': '拒绝理由',
		'00820': '转账方式名称',
		'00821': '转账时间',
		'00822': '登录密码',
		'00823': '互联网账号存在被盗风险，建议您定期更改密码以保护账户安全',
		'00824': '修改',
		'00825': '手机验证',
		'00826': '验证后，可用于快速找回登录密码及支付密码，接收账户余额变动提醒',
		'00827': '修改',
		'00828': '修改登录密码',
		'00829': '原密码',
		'00830': '当前密码',
		'00831': '新密码',
		'00832': '新密码',
		'00833': '确认密码',
		'00834': '请确认新密码',
		'00835': '保存',
		'00836': '取消',
		'00837': '验证码',
		'00838': '请输入验证码',
		'00839': '动态码',
		'00840': '请输入动态码',
		'00841': '点击“获取动态码”，将会发送验证码到您绑定的手机号',
		'00842': '新密码',
		'00843': '新密码',
		'00844': '确认密码',
		'00845': '请确认新密码',
		'00846': '保存',
		'00847': '取消',
		'00848': '绑定邮箱',
		'00849': '当前邮箱',
		'00850': '邮箱',
		'00851': '请输入邮箱',
		'00852': '验证码',
		'00853': '请输入验证码',
		'00854': '动态码',
		'00855': '请输入动态码',
		'00856': '保存',
		'00857': '取消',
		'00858': '绑定手机号',
		'00859': '当前手机号',
		'00860': '手机号',
		'00861': '请输入手机号',
		'00862': '验证码',
		'00863': '请输入验证码',
		'00864': '动态码',
		'00865': '请输入动态码',
		'00866': '保存',
		'00867': '取消',
		'00868': '绑定支付密码',
		'00869': '请输入6位支付密码，建议不要使用重复或连续数字',
		'00870': '验证码已发送至',
		'00871': '保存',
		'00872': '取消',
		'00873': '请输入新密码',
		'00874': '新密码不能与原密码相同！',
		'00875': '请再次输入密码',
		'00876': '两次输入密码不一致!',
		'00877': '请输入新密码',
		'00878': '新密码不能与原密码相同！',
		'00879': '请再次输入密码',
		'00880': '两次输入密码不一致!',
		'00881': '请输入正确的的邮箱',
		'00882': '请输入正确的手机号',
		'00883': '请输入原密码',
		'00884': '请输入正确的邮箱',
		'00885': '请输入验证码',
		'00886': '请输入动态验证码',
		'00887': '请输入正确的手机号',
		'00888': '请输入验证码',
		'00889': '请输入动态验证码',
		'00890': '获取验证码',
		'00891': '输入短信验证码',
		'00892': '请输入验证码',
		'00893': '请输入动态验证码',
		'00894': '获取动态码',
		'00895': '获取动态码',
		'00896': '获取动态码',
		'00897': '你还未绑定手机号，请先绑定手机号',
		'00898': '提示信息',
		'00899': '确定',
		'00900': '取消',
		'00901': '修改密码成功',
		'00902': '邮箱绑定成功',
		'00903L': '已发送(',
		'00903R': '秒)',
		'00904': '当前手机号已存在',
		'00905': '请勿重复点击',
		'00906': '手机号绑定成功',
		'00907L': '已发送(',
		'00907R': '秒)',
		'00908': '请设置支付密码',
		'00909': '请再次输入',
		'00910': '请输入数字',
		'00911': '修改支付密码成功',
		'00912': '两次密码输入不一样',
		'00913': '请设置支付密码',
		'00914L': '已发送(',
		'00914R': '秒)',
		'00915': '请勿重复点击',
		'00916': '修改密码成功',
		'00917': '退款/售后',
		'00918': '商品信息',
		'00919': '退款金额',
		'00920': '退款类型',
		'00921': '退款状态',
		'00922': '操作',
		'00923': '退款编号：',
		'00924': '\'退款成功\'',
		'00925': '\'退款中\'',
		'00926': '\'退款\'',
		'00927': '\'退货\'',
		'00928': '退款详情',
		'00929': '暂无相关订单',
		'00930': '上一页',
		'00931': '下一页',
		'00932': '撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请',
		'00933': '提示',
		'00934': '确认撤销',
		'00935': '暂不撤销',
		'00936': '撤销成功',
		'00937': '退款/售后',
		'00938': '退款详情',
		'00939': '平台维权',
		'00940': '商品',
		'00941': '数量',
		'00942': '金额',
		'00943': '退款金额',
		'00944': '退款原因',
		'00945': '请选择',
		'00946': '退款说明',
		'00947': '请输入退款说明(选填)',
		'00948': '提交',
		'00949': '协商记录',
		'00950': '协商记录',
		'00951': '买家',
		'00952': '卖家',
		'00953': '平台',
		'00954': '商品',
		'00955': '数量',
		'00956': '退款金额',
		'00957': '退款原因：',
		'00958': '退款金额：',
		'00959': '退款编号：',
		'00960': '申请时间：',
		'00961': '退款时间：',
		'00962': '未获取到该订单项退款信息',
		'00963': '请选择退款原因',
		'00964': '撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请',
		'00965': '提示',
		'00966': '确认撤销',
		'00967': '暂不撤销',
		'00968': '撤销成功',
		'00969': '订单列表',
		'00970': '订单详情',
		'00971': '订单状态：',
		'00972': '需付款：',
		'00973': '评价',
		'00974': '追评',
		'00975': '评价',
		'00976': '追评',
		'00977': '订单信息',
		'00978': '订单类型：',
		'00979': '订单编号：',
		'00980': '订单交易号：',
		'00981': '配送方式：',
		'00982': '退款途径：',
		'00983': '\'原路退款\'',
		'00984': '\'线下退款\'',
		'00985': '\'退款到余额\'',
		'00986': '创建时间：',
		'00987': '关闭时间：',
		'00988': '支付方式：',
		'00989': '支付时间：',
		'00990': '店铺活动：',
		'00991': '买家留言：',
		'00992': '收货信息',
		'00993': '收货人：',
		'00994': '手机号码：',
		'00995': '收货地址：',
		'00996': '发票信息',
		'00997': '发票类型：',
		'00998': '\'纸质发票\'',
		'00999': '\'电子发票\'',
		'01000': '发票抬头类型：',
		'01001': '\'个人\'',
		'01002': '\'企业\'',
		'01003': '发票抬头：',
		'01004': '发票内容：',
		'01005': '发票邮寄地址地址：',
		'01006': '发票接收邮箱：',
		'01007': '商品信息',
		'01008': '单价',
		'01009': '数量',
		'01010': '小计',
		'01011': '操作',
		'01012': '退款',
		'01013': '查看退款',
		'01014': '商品金额：',
		'01015': '运费：',
		'01016': '会员卡：',
		'01017': '税费：',
		'01018': '发票邮寄费：',
		'01019': '订单调整：',
		'01020': '优惠：',
		'01021': '优惠券：',
		'01022': '积分抵扣：',
		'01023': '使用余额：',
		'01024': '实付款：',
		'01025': '未获取到订单信息',
		'01026': '订单列表',
		'01027': '订单详情',
		'01028': '订单状态：',
		'01029': '需付款：',
		'01030': '评价',
		'01031': '追评',
		'01032': '评价',
		'01033': '追评',
		'01034': '订单信息',
		'01035': '订单类型：',
		'01036': '订单编号：',
		'01037': '订单交易号：',
		'01038': '配送方式：',
		'01039': '创建时间：',
		'01040': '关闭时间：',
		'01041': '支付方式：',
		'01042': '支付时间：',
		'01043': '店铺活动：',
		'01044': '买家留言：',
		'01045': '收货信息',
		'01046': '收货人：',
		'01047': '手机号码：',
		'01048': '收货地址：',
		'01049': '商品信息',
		'01050': '单价',
		'01051': '数量',
		'01052': '小计',
		'01053': '操作',
		'01054': '退款',
		'01055': '查看退款',
		'01056': '商品金额：',
		'01057': '运费：',
		'01058': '会员卡：',
		'01059': '税费：',
		'01060': '发票邮寄费：',
		'01061': '订单调整：',
		'01062': '优惠：',
		'01063': '优惠券：',
		'01064': '积分抵扣：',
		'01065': '使用余额：',
		'01066': '实付款：',
		'01067': '未获取到订单信息',
		'01068': '订单列表',
		'01069': '订单详情',
		'01070': '订单状态：',
		'01071': '需付款：',
		'01072': '评价',
		'01073': '追评',
		'01074': '评价',
		'01075': '追评',
		'01076': '自提点：',
		'01077': '提货码：',
		'01078': '营业时间：',
		'01079': '联系方式：',
		'01080': '详细地址：',
		'01081': '商品信息',
		'01082': '单价',
		'01083': '数量',
		'01084': '小计',
		'01085': '操作',
		'01086': '订单信息',
		'01087': '订单类型：',
		'01088': '订单编号：',
		'01089': '订单交易号：',
		'01090': '配送方式：',
		'01091': '创建时间：',
		'01092': '关闭时间：',
		'01093': '支付方式：',
		'01094': '支付时间：',
		'01095': '店铺活动：',
		'01096': '买家留言：',
		'01097': '收货信息',
		'01098': '收货人：',
		'01099': '手机号码：',
		'01100': '收货地址：',
		'01101': '退款',
		'01102': '查看退款',
		'01103': '商品金额：',
		'01104': '运费：',
		'01105': '会员卡：',
		'01106': '税费：',
		'01107': '发票邮寄费：',
		'01108': '订单调整：',
		'01109': '优惠：',
		'01110': '优惠券：',
		'01111': '使用余额：',
		'01112': '积分抵扣：',
		'01113': '实付款：',
		'01114': '未获取到订单信息',
		'01115': '订单列表',
		'01116': '订单详情',
		'01117': '订单状态：',
		'01118L': '距离订单自动关闭，剩余',
		'01118R': '',
		'01119': '需付款：',
		'01120': '评价',
		'01121': '追评',
		'01122': '评价',
		'01123': '追评',
		'01124': '核销码',
		'01125': '请将二维码出示给核销员',
		'01126': '核销码：',
		'01127': '核销信息',
		'01128': '核销次数：共',
		'01129L': '次/还剩',
		'01129R': '次',
		'01130': '有效期：',
		'01131': '永久有效',
		'01132': '核销记录',
		'01133': '核销人：',
		'01134': '核销时间：',
		'01135': '卡密信息',
		'01136': '卡号：',
		'01137': '密码：',
		'01138': '订单信息',
		'01139': '订单类型：',
		'01140': '订单编号：',
		'01141': '订单交易号：',
		'01142': '创建时间：',
		'01143': '关闭时间：',
		'01144': '支付方式：',
		'01145': '支付时间：',
		'01146': '店铺活动：',
		'01147': '买家留言：',
		'01148': '发票信息',
		'01149': '发票类型：',
		'01150': '\'纸质发票\'',
		'01151': '\'电子发票\'',
		'01152': '发票抬头类型：',
		'01153': '\'个人\'',
		'01154': '\'企业\'',
		'01155': '发票抬头：',
		'01156': '发票内容：',
		'01157': '发票邮寄地址地址：',
		'01158': '发票接收邮箱：',
		'01159': '商品信息',
		'01160': '单价',
		'01161': '数量',
		'01162': '小计',
		'01163': '操作',
		'01164': '退款',
		'01165': '查看退款',
		'01166': '商品金额：',
		'01167': '会员卡：',
		'01168': '税费：',
		'01169': '发票邮寄费：',
		'01170': '订单调整：',
		'01171': '优惠：',
		'01172': '优惠券：',
		'01173': '积分抵扣：',
		'01174': '使用余额：',
		'01175': '实付款：',
		'01176': '未获取到订单信息',
		'01177': '描述相符：',
		'01178': '好评',
		'01179': '中评',
		'01180': '差评',
		'01181': '请在此处输入您的评价',
		'01182': '请在此处输入您的追评',
		'01183L': '共6张，还能上传',
		'01183R': '张',
		'01184': '提交',
		'01185': '上传图片最大数量为6张',
		'01186': '商品的评价不能为空哦',
		'01187': '商品的评价不能为空哦',
		'01188': '评价成功',
		'01189': '我的订单',
		'01190': '全部订单',
		'01191': '待付款',
		'01192': '待发货',
		'01193': '待收货',
		'01194': '待评价',
		'01195': '商品信息',
		'01196': '单价',
		'01197': '数量',
		'01198': '实付款',
		'01199': '订单状态',
		'01200': '操作',
		'01201': '订单号：',
		'01202L': '距离订单自动关闭，剩余',
		'01202R': '',
		'01203': '订单详情',
		'01204': '评价',
		'01205': '追评',
		'01206': '评价',
		'01207': '追评',
		'01208': '暂无相关订单',
		'01209': '上一页',
		'01210': '下一页',
		'01211': '订单列表',
		'01212': '物流详情',
		'01213': '运单号码：',
		'01214': '物流公司：',
		'01215': '未获取到订单包裹信息！',
		'01216': '我的订单',
		'01217': '订单详情',
		'01218': '退款',
		'01219': '商品',
		'01220': '数量',
		'01221': '金额',
		'01222': '退款类型',
		'01223': '退款无需退货',
		'01224': '退货退款',
		'01225': '退款金额',
		'01226': '退款原因',
		'01227': '请选择',
		'01228': '退款说明',
		'01229': '请输入退款说明（选填）',
		'01230': '提交',
		'01231': '未获取到该订单项退款信息！',
		'01232': '申请成功！',
		'01233': '请选择退款原因',
		'01234': '退款/售后',
		'01235': '退款详情',
		'01236': '维权成功',
		'01237': '拒绝原因:',
		'01238': '如果商家拒绝，你可重新发起申请',
		'01239': '如果商家同意，将通过申请并退款给你',
		'01240': '如果商家逾期未处理，平台将自动通过申请并退款给你',
		'01241': '如果商家确认收货将会退款给你',
		'01242': '如果商家拒绝收货，该次退款将会关闭，你可以重新发起退款',
		'01243': '协商记录',
		'01244': '协商记录',
		'01245': '\'买家\'',
		'01246': '\'卖家\'',
		'01247': '收货人：',
		'01248': '联系电话：',
		'01249': '退货地址：',
		'01250': '商品',
		'01251': '数量',
		'01252': '退款金额',
		'01253': '退款方式：',
		'01254': '\'仅退款\'',
		'01255': '\'退款退货\'',
		'01256': '退款原因：',
		'01257': '退款金额：',
		'01258': '退款编号：',
		'01259': '申请时间：',
		'01260': '退款时间：',
		'01261': '平台维权',
		'01262': '输入发货物流',
		'01263': '物流公司',
		'01264': '请输入物流公司',
		'01265': '物流单号',
		'01266': '请输入物流单号',
		'01267': '物流说明',
		'01268': '选填',
		'01269': '取 消',
		'01270': '确 定',
		'01271': '未获取到该订单项退款信息！',
		'01272': '请输入物流公司',
		'01273': '请输入物流单号',
		'01274': '未获取到该订单项退款信息！',
		'01275': '撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请',
		'01276': '提示',
		'01277': '确认撤销',
		'01278': '暂不撤销',
		'01279': '撤销成功！',
		'01280': '核销台',
		'01281': '输入核销码',
		'01282': '核销',
		'01283': '请输入核销码',
		'01284': '确认',
		'01285': '请输入核销码',
		'01286': '核销记录',
		'01287': '核销验证',
		'01288': '数量：',
		'01289': '核销类型：',
		'01290': '核销状态：已核销',
		'01291': '核销人员：',
		'01292': '核销时间：',
		'01293': '确认使用',
		'01294': '核销记录',
		'01295': '商品信息',
		'01296': '单价',
		'01297': '数量',
		'01298': '核销员：',
		'01299': '暂无相关订单',
		'01300': '上一页',
		'01301': '下一页',
		'01302': '收货地址',
		'01303': '添加收货地址',
		'01304': '编辑',
		'01305': '确定要删除该地址吗？',
		'01306': '删除',
		'01307': '更多收货地址',
		'01308': '购买虚拟类商品需填写您的手机号，以方便商家与您联系',
		'01309': '手机号码',
		'01310': '请输入您的手机号码',
		'01311': '\'添加收货地址\'',
		'01312': '\'编辑收货地址\'',
		'01313': '姓名',
		'01314': '收货人姓名',
		'01315': '手机',
		'01316': '收货人手机号',
		'01317': '电话',
		'01318': '收货人固定电话（选填）',
		'01319': '地区',
		'01320': '请选择省',
		'01321': '请选择省',
		'01322': '请选择市',
		'01323': '请选择市',
		'01324': '请选择区/县',
		'01325': '请选择区/县',
		'01326': '详细地址',
		'01327': '定位小区、街道、写字楼',
		'01328': '取 消',
		'01329': '确 定',
		'01330': '是否使用余额',
		'01331': '不使用余额',
		'01332': '使用余额',
		'01333': '是否使用积分',
		'01334': '不使用积分',
		'01335': '使用积分',
		'01336': '配送方式',
		'01337': '发票信息',
		'01338': '无需发票',
		'01339': '需要发票',
		'01340': '发票类型：',
		'01341': '纸质',
		'01342': '电子',
		'01343': '抬头类型：',
		'01344': '个人',
		'01345': '企业',
		'01346': '发票信息：',
		'01347': '请填写抬头名称',
		'01348': '请填写纳税人识别号',
		'01349': '请填写邮寄地址',
		'01350': '请填写邮箱',
		'01351': '发票内容：',
		'01352': '商品',
		'01353': '价格',
		'01354': '数量',
		'01355': '小计',
		'01356': '优惠券',
		'01357': '已选择优惠券，已优惠',
		'01358': '不使用优惠券',
		'01359': '买家留言:',
		'01360': '留言前建议先与商家协调一致',
		'01361': '商品金额：',
		'01362': '运费：',
		'01363': '税费：',
		'01364': '发票邮寄费：',
		'01365': '优惠：',
		'01366': '积分：',
		'01367': '优惠券：',
		'01368': '使用余额：',
		'01369L': '共',
		'01369R': '件，应付金额：',
		'01370': '订单结算',
		'01371': '选择优惠券',
		'01372': '名称',
		'01373': '优惠',
		'01374': '使用',
		'01375L': '满',
		'01375R': '可用',
		'01376': '任意金额可用',
		'01377': '期限',
		'01378': '取消',
		'01379': '确认选择',
		'01380': '选择门店',
		'01381': '名称',
		'01382': '地址',
		'01383': '营业时间',
		'01384': '使用余额',
		'01385': '为了您的账户安全,请您先设置的支付密码',
		'01386': '可到"会员中心","账号安全","支付密码"中设置',
		'01387': '暂不设置',
		'01388': '立即设置',
		'01389': '支付密码',
		'01390': '忘记密码',
		'01391': '请输入手机号',
		'01392': '手机号格式错误',
		'01393': '请输入收货人',
		'01394': '长度在 1 到 20 个字符',
		'01395': '请选择省',
		'01396': '请选择市',
		'01397': '请选择区/县',
		'01398': '请输入详细地址',
		'01399': '选择配送时间',
		'01400': '请正确选择省',
		'01401': '请正确选择市',
		'01402': '请正确选择区/县',
		'01403': '请选择省',
		'01404': '请选择市',
		'01405': '请选择区/县',
		'01406': '未获取到创建订单所需数据！',
		'01407': '未获取到创建订单所需数据！',
		'01408': '配送方式',
		'01409': '物流配送',
		'01410': '全天',
		'01411': '全天',
		'01412': '周日',
		'01413': '周一',
		'01414': '周二',
		'01415': '周三',
		'01416': '周四',
		'01417': '周五',
		'01418': '周六',
		'01419': '至',
		'01420': '店铺未设置配送时间',
		'01421': '店铺未开启配送时间',
		'01422': '请填写发票抬头',
		'01423': '请填写纳税人识别号',
		'01424': '请填写发票邮寄地址',
		'01425': '请填写邮箱',
		'01426': '请填写正确的邮箱',
		'01427': '请选择发票内容',
		'01428': '门店自提',
		'01429': '物流配送',
		'01430': '取消',
		'01431': '确认',
		'01432': '订单提交中...',
		'01433': '请输入您的手机号码',
		'01434': '请输入正确的手机号码',
		'01435': '请先选择您的收货地址',
		'01436': '店铺"',
		'01437': '"未设置配送方式',
		'01438': '店铺"',
		'01439': '"没有可提货的门店,请选择其他配送方式',
		'01440': '请选择自提门店',
		'01441': '支付中',
		'01442': '专题活动详情：',
		'01443': '商品详情：',
		'01444': '商品信息',
		'01445': '价格',
		'01446': '数量',
		'01447L': '库存不足，剩余：',
		'01447R': '件',
		'01448': '购买数量:',
		'01449': '为您节省:',
		'01450': '套餐价:',
		'01451': '立即购买',
		'01452': '收货地址',
		'01453': '添加收货地址',
		'01454': '编辑',
		'01455': '确定要删除该地址吗？',
		'01456': '删除',
		'01457': '更多收货地址',
		'01458': '购买虚拟类商品需填写您的手机号，以方便商家与您联系',
		'01459': '手机号码',
		'01460': '请输入您的手机号码',
		'01461': '\'添加收货地址\'',
		'01462': '\'编辑收货地址\'',
		'01463': '姓名',
		'01464': '收货人姓名',
		'01465': '手机',
		'01466': '收货人手机号',
		'01467': '电话',
		'01468': '收货人固定电话（选填）',
		'01469': '地区',
		'01470': '请选择省',
		'01471': '请选择省',
		'01472': '请选择市',
		'01473': '请选择市',
		'01474': '请选择区/县',
		'01475': '请选择区/县',
		'01476': '详细地址',
		'01477': '定位小区、街道、写字楼',
		'01478': '取 消',
		'01479': '确 定',
		'01480': '是否使用余额',
		'01481': '不使用余额',
		'01482': '使用余额',
		'01483': '配送方式',
		'01484': '请先添加收货地址',
		'01485': '商家未配置配送方式',
		'01486': '商品',
		'01487': '价格',
		'01488': '数量',
		'01489': '活动',
		'01490': '组合套餐 ',
		'01491': '优惠 ',
		'01492': '买家留言:',
		'01493': '留言前建议先与商家协调一致',
		'01494': '商品金额：',
		'01495': '运费：',
		'01496': '税费：',
		'01497': '使用余额：',
		'01498L': '共',
		'01498R': '件，应付金额：',
		'01499': '订单结算',
		'01500': '选择门店',
		'01501': '名称',
		'01502': '地址',
		'01503': '营业时间',
		'01504': '选择平台优惠券',
		'01505': '名称',
		'01506': '优惠',
		'01507': '使用',
		'01508L': '满',
		'01508R': '可用',
		'01509': '任意金额可用',
		'01510': '期限',
		'01511': '取消',
		'01512': '确认选择',
		'01513': '选择优惠券',
		'01514': '名称',
		'01515': '优惠',
		'01517': '使用',
		'01518': '期限',
		'01519': '取消',
		'01520': '确认选择',
		'01521': '送达时间',
		'01522': '送达时间',
		'01523': '选择时间',
		'01524': '关闭',
		'01525': '确认选择',
		'01526': '使用余额',
		'01527': '为了您的账户安全,请您先设置的支付密码',
		'01528': '可到"会员中心","账号安全","支付密码"中设置',
		'01529': '暂不设置',
		'01530': '立即设置',
		'01531': '支付密码',
		'01532': '忘记密码',
		'01533': '请输入手机号',
		'01534': '手机号格式错误',
		'01535': '请输入收货人',
		'01536': '长度在 1 到 20 个字符',
		'01537': '请选择省',
		'01538': '请选择市',
		'01539': '请选择区/县',
		'01540': '请输入详细地址',
		'01541': '选择配送时间',
		'01542': '请选择省',
		'01543': '请选择市',
		'01544': '请选择区/县',
		'01545': '请选择省',
		'01546': '请选择市',
		'01547': '请选择区/县',
		'01548': '未获取到创建订单所需数据！',
		'01549': '未获取到创建订单所需数据！',
		'01550': '配送方式',
		'01551': '物流配送',
		'01552': '满',
		'01553': '可用',
		'01554': '任意金额可用',
		'01555': '订单提交中...',
		'01556': '请先选择您的收货地址',
		'01557': '店铺未设置配送方式',
		'01558': '店铺没有可提货的门店,请选择其他配送方式',
		'01559': '支付中',
		'01560': '团购进行中',
		'01561': '团购价',
		'01562': '暂无正在进行团购的商品，去首页看看吧',
		'01563': '上一页',
		'01564': '下一页',
		'01565': '团购插件未安装',
		'01566': '团购插件未安装',
		'01567': '团购商品',
		'01568': '距离结束仅剩',
		'01569': '此视频暂无法播放，请稍后再试',
		'01570': '团购插件未安装',
		'01571': '团购插件未安装',
		'01572': '团购插件未安装',
		'01573': '活动已结束',
		'01574': '件',
		'01575': '北京市',
		'01576': '北京',
		'01577': '北京市',
		'01578': '北京',
		'01579': '东城区',
		'01580': '东城',
		'01581': '您还未登录',
		'01582': '活动已结束',
		'01583': '商品已售罄',
		'01584': '购买数量不能为0',
		'01585': '活动已结束',
		'01586': '团购活动已结束',
		'01587': '视频',
		'01588': '图片',
		'01589': '关注商品',
		'01590': '联系客服',
		'01591': '联系客服',
		'01592': '团购',
		'01593': '\'天\'',
		'01594': '\'小时\'',
		'01595': '\'分钟\'',
		'01596': '\'秒\'',
		'01597': '团购价',
		'01598': '原价',
		'01599': '累计评价',
		'01600': '累计销量',
		'01601': '运费',
		'01602': '快递免邮',
		'01603': '快递不免邮',
		'01604': '配送至',
		'01605': '请选择配送地址',
		'01606': '\'请选择省\'',
		'01607': '\'请选择市\'',
		'01608': '\'请选择区/县\'',
		'01609': '服务',
		'01610': '由',
		'01611': '发货并提供售后服务',
		'01612': '数量',
		'01613': '库存',
		'01614L': '',
		'01614R': '起购',
		'01615': '库存不足',
		'01616': '立即抢购',
		'01617': '二维码图片',
		'01618': '该商品已下架',
		'01619': '二维码图片',
		'01620': '商品服务',
		'01621': '商品详情',
		'01622': '商品属性',
		'01623': '\'商品评价(\'',
		'01624': '\'商品评价\'',
		'01625': '全部评价',
		'01626': '好评',
		'01627': '中评',
		'01628': '差评',
		'01629': '店家回复：',
		'01630': '追加评价',
		'01631': '店家回复：',
		'01632': '上一页',
		'01633': '下一页',
		'01634': '该商品暂无评价哦',
		'01635': '售后保障',
		'01636': '收货地址',
		'01637': '添加收货地址',
		'01638': '编辑',
		'01639': '确定要删除该地址吗？',
		'01640': '删除',
		'01641': '更多收货地址',
		'01642': '购买虚拟类商品需填写您的手机号，以方便商家与您联系',
		'01643': '手机号码',
		'01644': '请输入您的手机号码',
		'01645': '\'添加收货地址\'',
		'01646': '\'编辑收货地址\'',
		'01647': '姓名',
		'01648': '收货人姓名',
		'01649': '手机',
		'01650': '收货人手机号',
		'01651': '电话',
		'01652': '收货人固定电话（选填）',
		'01653': '地区',
		'01654': '请选择省',
		'01655': '请选择省',
		'01656': '请选择市',
		'01657': '请选择市',
		'01658': '请选择区/县',
		'01659': '请选择区/县',
		'01660': '详细地址',
		'01661': '定位小区、街道、写字楼',
		'01662': '取 消',
		'01663': '确 定',
		'01664': '是否使用余额',
		'01665': '不使用余额',
		'01666': '使用余额',
		'01667': '配送方式',
		'01668': '请先添加收货地址',
		'01669': '商家未配置配送方式',
		'01670': '商品',
		'01671': '价格',
		'01672': '数量',
		'01673': '店铺活动',
		'01674L': '购买数量达',
		'01674R': ', 享受团购价',
		'01675': '买家留言',
		'01676': '留言前建议先与商家协调一致',
		'01677': '商品金额：',
		'01678': '运费：',
		'01679': '税费：',
		'01680': '优惠：',
		'01681': '使用余额：',
		'01682L': '共',
		'01682R': '件，应付金额：',
		'01683': '订单结算',
		'01684': '选择门店',
		'01685': '名称',
		'01686': '地址',
		'01687': '营业时间',
		'01688': '送达时间',
		'01689': '送达时间',
		'01690': '选择时间',
		'01691': '关闭',
		'01692': '确认选择',
		'01693': '使用余额',
		'01694': '为了您的账户安全,请您先设置的支付密码',
		'01695': '可到"会员中心","账号安全","支付密码"中设置',
		'01696': '暂不设置',
		'01697': '立即设置',
		'01698': '支付密码',
		'01699': '忘记密码',
		'01700': '请输入手机号',
		'01701': '手机号格式错误',
		'01702': '请输入收货人',
		'01703': '长度在 1 到 20 个字符',
		'01704': '请选择省',
		'01705': '请选择市',
		'01706': '请选择区/县',
		'01707': '请输入详细地址',
		'01708': '选择配送时间',
		'01709': '请选择省',
		'01710': '请选择市',
		'01711': '请选择区/县',
		'01712': '请选择省',
		'01713': '请选择市',
		'01714': '请选择区/县',
		'01715': '请正确选择省',
		'01716': '请正确选择市',
		'01717': '请正确选择区/县',
		'01718': '请选择省',
		'01719': '请选择市',
		'01720': '请选择区/县',
		'01721': '未获取到创建订单所需数据！',
		'01722': '未获取到创建订单所需数据！',
		'01723': '配送方式',
		'01724': '物流配送',
		'01725': '订单提交中...',
		'01726': '请输入您的手机号码',
		'01727': '请输入正确的手机号码',
		'01728': '请先选择您的收货地址',
		'01729': '店铺"',
		'01730': '"未设置配送方式',
		'01731': '店铺"',
		'01732': '"没有可提货的门店,请选择其他配送方式',
		'01733': '请选择自提门店',
		'01734': '支付中',
		'01735': '距离结束仅剩',
		'01736': '此视频暂无法播放，请稍后再试',
		'01737': '秒杀插件未安装',
		'01738': '秒杀插件未安装',
		'01739': '秒杀插件未安装',
		'01740': '限时秒杀活动还未开始',
		'01741': '限时秒杀活动已结束',
		'01742': '件',
		'01743': '北京市',
		'01744': '北京',
		'01745': '北京市',
		'01746': '北京',
		'01747': '东城区',
		'01748': '东城',
		'01749': '您还未登录',
		'01750': '商品已售罄',
		'01751': '购买数量不能为0',
		'01752': '活动已结束',
		'01753': '限时秒杀活动已结束',
		'01754': '视频',
		'01755': '图片',
		'01756': '关注商品',
		'01757': '联系客服',
		'01758': '联系客服',
		'01759': '限时秒杀',
		'01760': '\'天\'',
		'01761': '\'小时\'',
		'01762': '\'分钟\'',
		'01763': '\'秒\'',
		'01764': '秒杀价',
		'01765': '原价',
		'01766': '累计评价',
		'01767': '累计销量',
		'01768': '运费',
		'01769': '快递免邮',
		'01770': '快递不免邮',
		'01771': '配送至',
		'01772': '请选择配送地址',
		'01773': '\'请选择省\'',
		'01774': '\'请选择市\'',
		'01775': '\'请选择区/县\'',
		'01776': '服务',
		'01777': '由',
		'01778': '发货并提供售后服务',
		'01779': '数量',
		'01780': '库存',
		'01781': '立即抢购',
		'01782': '库存不足',
		'01783': '二维码图片',
		'01784': '该商品已下架',
		'01785': '二维码图片',
		'01786': '商品服务',
		'01787': '商品详情',
		'01788': '商品属性',
		'01789': '\'商品评价(\'',
		'01790': '\'商品评价\'',
		'01791': '全部评价',
		'01792': '好评',
		'01793': '中评',
		'01794': '差评',
		'01795': '店家回复：',
		'01796': '追加评价',
		'01797': '店家回复：',
		'01798': '上一页',
		'01799': '下一页',
		'01800': '该商品暂无评价哦',
		'01801': '售后保障',
		'01802': '收货地址',
		'01803': '添加收货地址',
		'01804': '编辑',
		'01805': '确定要删除该地址吗？',
		'01806': '删除',
		'01807': '更多收货地址',
		'01808': '购买虚拟类商品需填写您的手机号，以方便商家与您联系',
		'01809': '手机号码',
		'01810': '请输入您的手机号码',
		'01811': '\'添加收货地址\'',
		'01812': '\'编辑收货地址\'',
		'01813': '姓名',
		'01814': '收货人姓名',
		'01815': '手机',
		'01816': '收货人手机号',
		'01817': '电话',
		'01818': '收货人固定电话（选填）',
		'01819': '地区',
		'01820': '请选择省',
		'01821': '请选择省',
		'01822': '请选择市',
		'01823': '请选择市',
		'01824': '请选择区/县',
		'01825': '请选择区/县',
		'01826': '详细地址',
		'01827': '定位小区、街道、写字楼',
		'01828': '取 消',
		'01829': '确 定',
		'01830': '是否使用余额',
		'01831': '不使用余额',
		'01832': '使用余额',
		'01833': '配送方式',
		'01834': '请先添加收货地址',
		'01835': '商家未配置配送方式',
		'01836': '商品',
		'01837': '价格',
		'01838': '数量',
		'01839': '店铺活动',
		'01840': '限时秒杀 ',
		'01841': '买家留言',
		'01842': '留言前建议先与商家协调一致',
		'01843': '商品金额：',
		'01844': '运费：',
		'01845': '税费：',
		'01846': '优惠：',
		'01847': '使用余额：',
		'01848L': '共',
		'01848R': '件，应付金额：',
		'01849': '订单结算',
		'01850': '选择门店',
		'01851': '名称',
		'01852': '地址',
		'01853': '营业时间',
		'01854': '送达时间',
		'01855': '送达时间',
		'01856': '选择时间',
		'01857': '关闭',
		'01858': '确认选择',
		'01859': '使用余额',
		'01860': '为了您的账户安全,请您先设置的支付密码',
		'01861': '可到"会员中心","账号安全","支付密码"中设置',
		'01862': '暂不设置',
		'01863': '立即设置',
		'01864': '支付密码',
		'01865': '忘记密码',
		'01866': '请输入手机号',
		'01867': '手机号格式错误',
		'01868': '请输入收货人',
		'01869': '长度在 1 到 20 个字符',
		'01870': '请选择省',
		'01871': '请选择市',
		'01872': '请选择区/县',
		'01873': '请输入详细地址',
		'01874': '选择配送时间',
		'01875': '请选择省',
		'01876': '请选择市',
		'01877': '请选择区/县',
		'01878': '请选择省',
		'01879': '请选择市',
		'01880': '请选择区/县',
		'01881': '请正确选择省',
		'01882': '请正确选择市',
		'01883': '请正确选择区/县',
		'01884': '请选择省',
		'01885': '请选择市',
		'01886': '请选择区/县',
		'01887': '未获取到创建订单所需数据！',
		'01888': '未获取到创建订单所需数据！',
		'01889': '配送方式',
		'01890': '物流配送',
		'01891': '订单提交中...',
		'01892': '请输入您的手机号码',
		'01893': '请输入正确的手机号码',
		'01894': '请先选择您的收货地址',
		'01895': '店铺"',
		'01896': '"未设置配送方式',
		'01897': '店铺"',
		'01898': '"没有可提货的门店,请选择其他配送方式',
		'01899': '请选择自提门店',
		'01900': '支付中',
		'01901': '敬请期待',
		'01902': '即将开始',
		'01903': '抢购中',
		'01904': '已结束',
		'01905': '\'敬请期待\'',
		'01906': '秒杀价',
		'01907': '立即抢购',
		'01908': '上一页',
		'01909': '下一页',
		'01910': '暂无正在进行秒杀的商品，去首页看看吧',
		'01911': '距离结束仅剩',
		'01912': '秒杀插件未安装',
		'01913': '秒杀插件未安装',
		'01914': '秒杀已结束',
		'01915': '活动已结束',
		'01916': '秒杀商品',
		'01917': '秒杀活动还未开启，敬请期待！',
	},
	
}
